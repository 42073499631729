import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Toast } from "primereact/toast";
import axios from "axios";
import { useData } from "../../../context/DataContext";

export function UserClossedRecords() {
  const { serverURL, isMobile } = useData(); // Fetch the server URL and mobile status from the context
  const [usersRecords, setUsersRecords] = useState([]);
  const toast = useRef(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [expandedRows, setExpandedRows] = useState(null); // To manage expanded rows

  // Global search filter handler
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;
    setFilters(_filters);
  };

  // Render search input for the header
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText onChange={onGlobalFilterChange} placeholder="Search" />
        </IconField>
      </div>
    );
  };

  const header = renderHeader();

  // Fetch records from the server
  const fetchRecords = async () => {
    try {
      const response = await axios.get(
        `${serverURL}/dashboard/admin/getOpenTrades.php`
      );
      if (response.data.status === "success") {
        setUsersRecords(response.data.data); // Set the response data into the state
      }
    } catch (error) {
      console.error("Error fetching profile details:", error);
    }
  };

  useEffect(() => {
    fetchRecords(); // Fetch records when component mounts
  }, []);

  // Button for closing a record
  const closedRecordButton = (rowData) => (
    <Button
      label="Close"
      severity="danger"
      outlined
      style={{
        fontSize: isMobile ? "10px" : "12px",
        height: isMobile ? "12px" : "25px",
        boxShadow: "none",
      }}
      onClick={() => deleteTicket(rowData)}
    />
  );

  const fetchDataValue = async (symbol) => {
    try {
      const response = await fetch(
        `https://api.polygon.io/v1/conversion/USD/${symbol.substring(
          3
        )}?amount=100&precision=2&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
      );
      const responseData = await response.json();
      return {
        ask: responseData.last.ask.toFixed(5),
        bid: responseData.last.bid.toFixed(5),
      };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { ask: null, bid: null };
    }
  };

  const deleteTicket = async (value) => {
    const { ask, bid } = await fetchDataValue(value.fullSymbol);

    try {
      const response = await axios.post(
        "https://makassab.com/trading_platform/backend/dashboard/admin/editStatusTrades.php",
        {
          traders_id: value.traderId,
          ticket: value.tradeId,
          lastPrice: value.tradesTypesName === "buy" ? bid : ask,
          profit:
            value.tradesTypesName === "buy"
              ? (bid - value.orginalPrice) * value.volume
              : (value.orginalPrice - ask) * value.volume,
        }
      );
      if (response.data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "success",
          detail: `ticket# ${value.tradeId} closed successfully`,
        });
        fetchRecords();
      }
    } catch (error) {
      console.log("error API");
    }
  };

  // Template for expanded row content (sub-table)
  const rowExpansionTemplate = (rowData) => {
    return (
      <DataTable
        value={rowData.trades} // Use the trades data for each trader
        size={"small"}
        filters={filters} // Apply the global filter to sub-table
        emptyMessage="No trades found"
        className="font-normal text-sm text-900"
        tableStyle={{
          maxHeight: "100vh",
        }}
        scrollable
        scrollHeight="100vh"
      >
        <Column
          field="tradeId"
          header="Ticket ID"
          style={{
            fontSize: isMobile ? "10px" : "14px",
          }}
        />
        <Column
          field="fullSymbol"
          header="Symbol"
          style={{
            fontSize: isMobile ? "10px" : "14px",
          }}
        />
        <Column
          field="timeStamp"
          header="Time Open"
          style={{
            fontSize: isMobile ? "10px" : "14px",
          }}
        />
        <Column
          field="volume"
          header="Volume"
          style={{
            fontSize: isMobile ? "10px" : "14px",
          }}
        />
        <Column
          field="orginalPrice"
          header="Original Price"
          style={{
            fontSize: isMobile ? "10px" : "14px",
          }}
        />
        <Column
          field="tradesTypesName"
          header="Trade Type"
          style={{
            fontSize: isMobile ? "10px" : "14px",
          }}
        />
        <Column
          header="Close Trade"
          body={closedRecordButton}
          style={{
            fontSize: isMobile ? "10px" : "14px",
          }}
        />
      </DataTable>
    );
  };

  return (
    <div className={`flex flex-column w-full ${isMobile ? "p-6" : "p-8"}`}>
      <Toast ref={toast} />
      <DataTable
        value={usersRecords} // Main table data
        size={"small"}
        filters={filters} // Apply the global filter to the main table
        emptyMessage="No users found"
        header={header} // Render the search bar in the header
        className="font-normal text-sm text-900"
        tableStyle={{
          maxHeight: "100vh",
        }}
        scrollable
        scrollHeight="100vh"
        expandedRows={expandedRows} // Expanded rows state
        onRowToggle={(e) => setExpandedRows(e.data)} // Handle row toggle for expanding
        rowExpansionTemplate={rowExpansionTemplate} // Set the template for expanded rows
        dataKey="traderId" // Key for proper row expansion
      >
        <Column expander style={{ width: "3em" }} /> {/* Expander column */}
        <Column
          field="traderId"
          header="Trader ID"
          style={{
            fontSize: isMobile ? "10px" : "14px",
            height: isMobile ? "20px" : "35px",
          }}
        />
        <Column
          header="Trader Name"
          style={{
            fontSize: isMobile ? "10px" : "14px",
            height: isMobile ? "20px" : "35px",
          }}
          body={
            (rowData) => `${rowData.traderFirstName} ${rowData.traderLastName}` // Concatenating first and last name
          }
        />
        <Column
          field="traderEmail"
          header="Email"
          style={{
            fontSize: isMobile ? "10px" : "14px",
            height: isMobile ? "20px" : "35px",
          }}
        />
      </DataTable>
    </div>
  );
}
