import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { Menu } from "primereact/menu";
// import { ChangePasswordDialog } from "../ChangePasswordPage/ChangePasswordPage";

export function AccountBox() {
  const navigate = useNavigate();

  const [selectedStatusAccount, setSelectedStatusAccount] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false); // State to control dialog visibility
  const menuRight = useRef(null);

  const StatusAccount = [
    { name: "All", code: "All" },
    { name: "Active", code: "Active" },
    { name: "Rejected", code: "Rejected" },
    { name: "Processing", code: "Processing" },
    { name: "Pending", code: "Pending" },
    { name: "Inactive", code: "Inactive" },
  ];

  const items = [
    {
      label: "",
      items: [
        {
          label: "Change Password",
          icon: "pi pi-lock-open",
          command: () => setDialogVisible(true),
        },
      ],
    },
  ];

  return (
    <div className="flex">
      <div className="flex font-medium text-base	border-round">
        <div className="flex flex-column">
          <Dropdown
            value={selectedStatusAccount}
            onChange={(e) => setSelectedStatusAccount(e.value)}
            options={StatusAccount}
            optionLabel="name"
            placeholder="Status"
            className="w-full md:w-14rem h-2rem align-items-center"
          />
          <div
            className="surface-card shadow-2 border-round mt-3"
            style={{ width: "30rem", height: "11rem" }}
          >
            <div className="flex flex-column">
              <div className="flex flex-row-reverse">
                <div className="flex p-2 border-round bg-primary">Active</div>
              </div>
              <div className="flex text-900	ml-4">11920</div>
              <div className="flex justify-content-between ml-4 mt-3">
                <div className="flex flex-row align-items-center justify-content-start ">
                  <div className="flex align-items-center">1.5</div>
                  <div className="flex font-light text-xs align-items-center justify-content-center mt-1 ml-1">
                    USD
                  </div>
                </div>
                <div className="flex align-items-center justify-content-center"></div>
                <div className="flex flex-row align-items-center justify-content-center mr-4">
                  <Button
                    label="Deposit"
                    severity="secondary"
                    outlined
                    style={{ height: "25px", boxShadow: "none" }}
                  />

                  <Button
                    label="Trade"
                    severity="secondary"
                    outlined
                    className="ml-2"
                    style={{ height: "25px", boxShadow: "none" }}
                  />

                  <Button
                    icon="pi pi-cog"
                    style={{ fontSize: "10px", color: "var(--surface-800)" }}
                    text
                    aria-controls="popup_menu_right"
                    aria-haspopup
                    onClick={(event) => menuRight.current.toggle(event)}
                  />
                  <Menu
                    model={items}
                    popup
                    ref={menuRight}
                    id="popup_menu_right"
                    popupAlignment="right"
                    className="font-light text-sm"
                  />
                </div>
              </div>
              <div className="flex align-items-center justify-content-center mt-5">
                <Button
                  label="Start Trading"
                  severity="secondary"
                  outlined
                  style={{ height: "25px", boxShadow: "none" }}
                  onClick={() => {
                    navigate("/trading");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Dialog for Change Password */}
      {/* <ChangePasswordDialog
        dialogVisible={dialogVisible}
        setDialogVisible={setDialogVisible}
      ></ChangePasswordDialog> */}
    </div>
  );
}
