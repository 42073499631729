// index.js or App.js
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
import "primereact/resources/themes/saga-blue/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { DataProvider } from "./context/DataContext"; // Adjust the path to your DataContext file
import App from "./App"; // Assuming App is your main component

ReactDOM.render(
  <React.StrictMode>
    <DataProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </DataProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
