import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useData } from "../../context/DataContext";
import axios from "axios";

export function NewBankAccountDialog({
  setNewBankAccountDialogIsVisable,
  newBankAccountDialogIsVisable,
}) {
  const { serverURL, isMobile, addNewAccountUpdate, setAddNewAccountUpdata } =
    useData();
  const [accountNamevalue, setAccountNameValue] = useState("");
  const [accountNumbervalue, setAccountNumberValue] = useState("");
  const [moreInfovalue, setMoreInfoValue] = useState("");

  const handleAddNewAccount = async () => {
    try {
      // Create a FormData object
      const formData = new FormData();
      formData.append("name", accountNamevalue);
      formData.append("number", accountNumbervalue);
      formData.append("moreInfo", moreInfovalue);
      formData.append("isActive", 1); // or convert to string: formData.append("isActive", "1");

      const response = await axios.post(
        "https://makassab.com/trading_platform/backend/dashboard/admin/addNewPayments.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure the request is sent as form-data
          },
        }
      );

      if (response.data.status === "success") {
        setAddNewAccountUpdata(!addNewAccountUpdate);
        setNewBankAccountDialogIsVisable(false);
      }
    } catch (error) {
      console.log("API issue", error);
    }
  };

  return (
    <div className="card flex justify-content-center">
      <Dialog
        // header="New Bank Account"
        visible={newBankAccountDialogIsVisable}
        // style={{ width: "50vw" }}
        onHide={() => setNewBankAccountDialogIsVisable(false)}
      >
        <div className="flex flex-column mt-3 w-full">
          <div
            className="flex align-items-center justify-content-start mb-3"
            // style={{ width: "100%" }}
          >
            <div className="flex align-items-center " style={{ width: "50%" }}>
              Account Name:
            </div>
            <div
              className="flex align-items-center justify-content-center"
              // style={{ width: "50%" }}
            >
              <InputText
                value={accountNamevalue}
                style={{ width: "100%" }}
                onChange={(e) => setAccountNameValue(e.target.value)}
              />
            </div>
          </div>
          <div
            className="flex align-items-center justify-content-start mb-3"
            // style={{ width: "100%" }}
          >
            <div className="flex align-items-center " style={{ width: "50%" }}>
              Account Number:
            </div>
            <div
              className="flex align-items-center justify-content-center"
              // style={{ width: "50%" }}
            >
              <InputText
                value={accountNumbervalue}
                style={{ width: "100%" }}
                onChange={(e) => setAccountNumberValue(e.target.value)}
              />
            </div>
          </div>
          <div
            className="flex flex-column "
            // style={{ width: "100%" }}
          >
            <div
              className="flex align-items-center justify-content-start"
              style={{ width: "50%" }}
            >
              More Info:
            </div>
            <div
              className="flex align-items-center justify-content-start mt-1"
              // style={{ width: "50%" }}
            >
              <InputTextarea
                autoResize
                value={moreInfovalue}
                style={{ width: "100%" }}
                onChange={(e) => setMoreInfoValue(e.target.value)}
                rows={5}
                cols={30}
              />
            </div>
          </div>
          <Button
            label="Add"
            className="w-full mt-4"
            style={{ backgroundColor: "#00afab" }}
            onClick={handleAddNewAccount}
          />
        </div>
      </Dialog>
    </div>
  );
}
