import React from "react";

export function FundsPage() {
  return (
    <div
      className="flex flex-wrap column-gap-4 row-gap-6 font-bold border-round"
      style={{ width: "100%", padding: "4rem" }}
    >
      <div
        className="flex flex-column surface-card shadow-2 font-bold border-round "
        style={{ width: "27rem" }}
      >
        <div className="flex align-items-center justify-content-start text-base ml-5 mt-2">
          <div className="flex align-items-center font-normal text-color-secondary	w-12rem">
            Total Balance
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            100.000.000
          </div>
        </div>

        <div className="flex align-items-center justify-content-start text-base ml-5 mt-2">
          <div className="flex align-items-center font-normal text-color-secondary	w-12rem">
            Date-Time
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            2024.03.12 21:33:14
          </div>
        </div>

        <div className="flex align-items-center justify-content-start text-base ml-5 mt-2">
          <div className="flex align-items-center font-normal text-color-secondary	w-12rem">
            Deposit
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            100.000
          </div>
        </div>

        <div className="flex align-items-center justify-content-start text-base ml-5 mt-1">
          <div className="flex align-items-center font-normal text-color-secondary	w-12rem">
            Profits
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            1000
          </div>
        </div>

        <div className="flex align-items-center justify-content-start text-base ml-5 mt-1 mb-2">
          <div className="flex align-items-center font-normal text-color-secondary	w-12rem">
            Balance
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            101.000
          </div>
        </div>
      </div>
      <div
        className="flex flex-column surface-card shadow-2 font-bold border-round "
        style={{ width: "27rem" }}
      >
        <div className="flex align-items-center justify-content-start text-base ml-5 mt-2">
          <div className="flex align-items-center font-normal text-color-secondary	w-12rem">
            Total Balance
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            100.000.000
          </div>
        </div>

        <div className="flex align-items-center justify-content-start text-base ml-5 mt-2">
          <div className="flex align-items-center font-normal text-color-secondary	w-12rem">
            Date-Time
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            2024.03.12 21:33:14
          </div>
        </div>

        <div className="flex align-items-center justify-content-start text-base ml-5 mt-2">
          <div className="flex align-items-center font-normal text-color-secondary	w-12rem">
            Deposit
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            100.000
          </div>
        </div>

        <div className="flex align-items-center justify-content-start text-base ml-5 mt-1">
          <div className="flex align-items-center font-normal text-color-secondary	w-12rem">
            Profits
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            1000
          </div>
        </div>

        <div className="flex align-items-center justify-content-start text-base ml-5 mt-1 mb-2">
          <div className="flex align-items-center font-normal text-color-secondary	w-12rem">
            Balance
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            101.000
          </div>
        </div>
      </div>
    </div>
  );
}
