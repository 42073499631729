import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useData } from "../../../context/DataContext";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputSwitch } from "primereact/inputswitch";
import { Toast } from "primereact/toast";
import { NewUserPasswordAdminDialog } from "../../Dialog/NewUserPasswordAdminDialog";
import axios from "axios";

export function UserPage() {
  const { serverURL, isMobile } = useData();
  const [
    newUserPasswordAdminDialogIsVisable,
    setNewUserPasswordAdminDialogIsVisable,
  ] = useState(false);
  const toast = useRef(null);
  const [changePasswordAlert, setChangePasswordAlert] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState(null);

  const [users, setUsers] = useState([]);

  const fetchTreaders = async () => {
    try {
      const response = await axios.get(
        "https://makassab.com/trading_platform/backend/dashboard/admin/getTraders.php"
      );
      if (response.data.status === "success") {
        setUsers(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching profile details:", error);
    }
  };

  useEffect(() => {
    fetchTreaders();
  }, []);

  useEffect(() => {
    if (changePasswordAlert === 1) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: `${selectedUserName} password changed successfully`,
      });
      setChangePasswordAlert(0); // Reset the alert status
    }
  }, [changePasswordAlert]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const onRowEditComplete = async (e) => {
    let { newData } = e;
    try {
      const formData = new FormData();
      formData.append("id", newData.id);
      formData.append("isActive", newData.isActive); // or convert to string: formData.append("isActive", "1");

      const response = await axios.post(
        "https://makassab.com/trading_platform/backend/dashboard/admin/editStatusTraders.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure the request is sent as form-data
          },
        }
      );
      if (response.data.status === "success") {
        fetchTreaders();
      }
    } catch (error) {
      console.log("API issue");
    }
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        style={{
          fontSize: isMobile ? "10px" : "14px",
          width: isMobile ? "75px" : "100%",
          height: isMobile ? "25px" : "100%",
        }}
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const ActivationBodyTemplate = (rowData) => {
    return rowData.isActive === "1" ? "Active" : "Inactive";
  };

  const ActivationEditor = (options) => {
    return (
      <InputSwitch
        style={{
          width: isMobile ? "45px" : "",
          height: isMobile ? "21px" : "",
        }}
        checked={options.value === "1"} // Toggle based on isActive value (1 for Active, 0 for Inactive)
        onChange={(e) => options.editorCallback(e.value ? "1" : "0")} // Store "1" for Active and "0" for Inactive
      />
    );
  };

  const allowEdit = (rowData) => {
    return rowData.name !== "Blue Band";
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText onChange={onGlobalFilterChange} placeholder="Search" />
        </IconField>
      </div>
    );
  };

  const header = renderHeader();

  const changePasswordButton = (rowData) => (
    <Button
      icon="pi pi-user-edit"
      rounded
      outlined
      severity="secondary"
      aria-label="Bookmark"
      style={{
        fontSize: "8px",
        height: isMobile ? "25px" : "29px",
        width: isMobile ? "27px" : "30px",
        boxShadow: "none",
      }}
      onClick={() => {
        setSelectedUserId(rowData.id);
        setSelectedUserName(rowData.firstName + " " + rowData.lastName);
        setNewUserPasswordAdminDialogIsVisable(true);
      }}
    />
  );

  // const deleteUserButton = (rowData) => (
  //   <Button
  //     icon="pi pi-user-minus"
  //     rounded
  //     outlined
  //     severity="secondary"
  //     aria-label="Bookmark"
  //     style={{
  //       fontSize: "8px",
  //       height: isMobile ? "25px" : "29px",
  //       width: isMobile ? "27px" : "30px",
  //       boxShadow: "none",
  //     }}
  //     onClick={() => {}}
  //   />
  // );

  return (
    <div className={`flex flex-column w-full ${isMobile ? "p-6" : "p-8"}`}>
      <Toast ref={toast} />
      <NewUserPasswordAdminDialog
        newUserPasswordAdminDialogIsVisable={
          newUserPasswordAdminDialogIsVisable
        }
        setNewUserPasswordAdminDialogIsVisable={
          setNewUserPasswordAdminDialogIsVisable
        }
        selectedUserId={selectedUserId}
        selectedUserName={selectedUserName}
        setChangePasswordAlert={setChangePasswordAlert}
      />
      <DataTable
        value={users}
        editMode="row"
        dataKey="id"
        onRowEditComplete={onRowEditComplete}
        size={"small"}
        filters={filters}
        emptyMessage=" "
        header={header}
        className="font-normal text-sm text-900"
        tableStyle={{ maxHeight: "100vh" }}
        scrollable
        scrollHeight="100vh"
      >
        <Column
          field="status"
          header="Online"
          body={(rowData) => (
            <span
              className="ml-2 mr-2 "
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: rowData.status === "Online" ? "green" : "red",
              }}
            ></span>
          )}
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        />
        <Column
          field="id"
          header="User ID"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        <Column
          field="firstName"
          header="First Name"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        <Column
          field="lastName"
          header="Last Name"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        <Column
          field="email"
          header="Email"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        <Column
          field="phoneNo"
          header="PhoneNo"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        <Column
          field="countryName"
          header="Country"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        <Column
          field="balance"
          header="Balance"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        <Column
          field="totalDeposite"
          header="Total Deposite"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        <Column
          field="totalCredit"
          header="Total Credit"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        <Column
          field="totalWithdrawals"
          header="Total Withdrawals"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        {/* <Column
          header="delete User"
          style={{ fontSize: isMobile ? "10px" : "15px", width: "20%" }}
          body={deleteUserButton}
        ></Column> */}
        <Column
          field="isActive"
          header="Account Status"
          body={ActivationBodyTemplate} // Custom body to display Active/Inactive
          editor={(options) => ActivationEditor(options)} // Editor for toggling Active/Inactive
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        {/* <Column
          field="confirmationCode"
          header="Confirmation Code"
          editor={(options) => textEditor(options)}
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column> */}
        <Column
          header="Change Password"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
          body={changePasswordButton}
        ></Column>
        <Column
          rowEditor={allowEdit}
          // headerStyle={{ width: "10%", minWidth: "8rem" }}
          bodyStyle={{ textAlign: "center" }}
        ></Column>
      </DataTable>
    </div>
  );
}
