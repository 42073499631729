import React, { useState } from "react";
import { PriceChart } from "../Chart/PriceChart";
import { PriceTable } from "../Table/PriceTable";
import { MobileSidebarMenu } from "../SidebarMenu/MobileSidebarMenu";
import { TradeTableMobile } from "../Table/TradeTableMobile";
import { DeletedMobileDialog } from "../Dialog/DeletedMobileDialog";
import { PriceTableMobileDialog } from "../Dialog/PriceTableMobileDialog";
import { MobileTopHeader } from "../TopHeader/MobileTopHeader";
import { NewOrderDialog } from "../Dialog/NewOrderDialog";
import { MobileDownHeaderMenu } from "../MobileDownHeader/MobileDownHeaderMenu";
import { NewOrderMobileDialog } from "../Dialog/NewOrderMobileDialog";
import { useData } from "../../context/DataContext";

export function MobileTradingPage() {
  const [newOrderDialogVisible, setNewOrderDialogVisible] = useState(false);
  const [sidebarMenu, setSidebarMenu] = useState(false);
  const [visibleDeleteSidebar, setVisibleDeleteSidebar] = useState(false);
  const [visiblePriceTableSidebar, setVisiblePriceTableSidebar] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemSymbol, setSelectedItemSymbol] = useState(null);
  const [FreeMarginMobilePublic, setFreeMarginMobilePublic] = useState(0);
  const [FreeMarginLevelPublic, setFreeMarginLevelPublic] = useState(0);

  const { componentShow, symbolType, setSymbolType } = useData();

  const fixedFooterStyle = {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: 10, // Keeps it above other content
  };

  return (
    <div className="flex flex-column">
      {componentShow === "chart" && (
        <MobileTopHeader
          setSidebarMenu={setSidebarMenu}
          setNewOrderDialogVisible={setNewOrderDialogVisible}
        />
      )}
      <div className="flex flex-row">
        {sidebarMenu && componentShow === "chart" && (
          <div className="flex">
            <MobileSidebarMenu />
          </div>
        )}
        <div style={{ height: "100%", width: "100%" }}>
          <div style={{ width: "100%", height: "100%" }}>
            {componentShow === "chart" && (
              <div>
                {/* <NewOrderDialog
                  visible={newOrderDialogVisible}
                  setVisible={setNewOrderDialogVisible}
                /> */}
                <PriceChart />
              </div>
            )}
            {componentShow === "priceTable" && (
              <div>
                <PriceTableMobileDialog
                  visible={visiblePriceTableSidebar}
                  setVisible={setVisiblePriceTableSidebar}
                  // setNewOrderDialogVisible={setNewOrderDialogVisible}
                  selectedItemSymbol={selectedItemSymbol}
                />
                {/* <NewOrderDialog
                  // fullSymbolData={false}
                  // visible={newOrderDialogVisible}
                  // setVisible={setNewOrderDialogVisible}
                  setVisiblePriceTable={setVisiblePriceTableSidebar}
                /> */}
                <PriceTable
                  mobile={true}
                  setVisible={setVisiblePriceTableSidebar}
                  setSelectedItemSymbol={setSelectedItemSymbol} // Pass setter function here
                />
              </div>
            )}
            {componentShow === "tradingTable" && (
              <div>
                <DeletedMobileDialog
                  visible={visibleDeleteSidebar}
                  setVisible={setVisibleDeleteSidebar}
                  // setNewOrderDialogVisible={setNewOrderDialogVisible}
                  selectedItem={selectedItem} // Pass selected item here
                  setSelectedItem={setSelectedItem}
                />
                {/* <NewOrderDialog
                  fullSymbolData={true}
                  visible={newOrderDialogVisible}
                  setVisible={setNewOrderDialogVisible}
                /> */}
                <TradeTableMobile
                  setVisible={setVisibleDeleteSidebar}
                  setSelectedItem={setSelectedItem}
                  setFreeMarginMobilePublic={setFreeMarginMobilePublic}
                  setFreeMarginLevelPublic={setFreeMarginLevelPublic}
                />
              </div>
            )}
            {componentShow === "NewOrderMobileDialog" && (
              <div>
                <NewOrderMobileDialog
                  selectedItemSymbol={selectedItemSymbol}
                  setVisiblePriceTable={setVisiblePriceTableSidebar}
                  setVisibleDeleteSidebar={setVisibleDeleteSidebar}
                  FreeMarginMobilePublic={FreeMarginMobilePublic}
                  FreeMarginLevelPublic={FreeMarginLevelPublic}
                  symbolType={symbolType}
                  setSymbolType={setSymbolType}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={fixedFooterStyle}>
        <MobileDownHeaderMenu />
      </div>
    </div>
  );
}
