import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export function ActivityAccountPage() {
  const [acccount, setAccount] = useState([
    {
      "Activity Date": "2024-05-01",
      "Activity Details": "Deposit",
      Status: "Completed",
    },
    {
      "Activity Date": "2024-04-30",
      "Activity Details": "Withdrawal",
      Status: "Pending",
    },
    {
      "Activity Date": "2024-04-29",
      "Activity Details": "Transfer",
      Status: "Completed",
    },
  ]);

  return (
    <div
      className="flex font-bold border-round"
      style={{ width: "100%", height: "100%", padding: "4rem" }}
    >
      <div className="flex flex-column w-full">
        <div className="flex flex-column border-round">
          <div className="flex">My Recent Activity</div>
          <div className="mt-3">
            <DataTable value={acccount} tableStyle={{ minWidth: "50rem" }}>
              <Column field="Activity Date" header="Activity Date"></Column>
              <Column
                field="Activity Details"
                header="Activity Details"
              ></Column>
              <Column field="Status" header="Status"></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}
