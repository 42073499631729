import React, { useState, useRef, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { useData } from "../../context/DataContext";

export function PriceTableMobileDialog({
  visible,
  setVisible,
  setNewOrderDialogVisible,
  selectedItemSymbol, // Receive the selected item
}) {
  const { setComponentShow, setComponentShowFrom } = useData();
  return (
    <Sidebar
      visible={visible}
      position="bottom"
      onHide={() => setVisible(false)}
      style={{ height: "25%" }}
      className="custom-sidebar"
    >
      <div className="flex flex-column mt-2">
        {selectedItemSymbol && (
          <div className="flex align-items-center justify-content-center text-900 p-2">
            USD{selectedItemSymbol.Symbol}
          </div>
        )}

        <div className="flex align-items-center justify-content-center p-2">
          <Button
            label="New Order"
            text
            className="text-900"
            style={{ boxShadow: "none" }}
            // onClick={() => setNewOrderDialogVisible(true)}
            onClick={() => {
              setComponentShow("NewOrderMobileDialog");
              setComponentShowFrom("priceTable");
            }}
          />
        </div>
        <div className="flex align-items-center justify-content-center p-2">
          <Button
            label="Chart"
            text
            className="text-900"
            style={{ boxShadow: "none" }}
            onClick={() => {
              setVisible(false);
              setComponentShow("chart");
            }}
          />
        </div>
      </div>
    </Sidebar>
  );
}
