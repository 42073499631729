import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

export function TransferPage({}) {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [transferAmount, setTransferAmount] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const account = [];

  const handleSubmit = () => {
    setTransferAmount(0);
  };

  return (
    <div className="flex flex-column w-full p-8" style={{ height: "100vh" }}>
      <div className="flex flex-column border-round">
        <div className="flex justify-content-start flex-wrap">
          <div className="flex font-medium">
            Change Trading Account Password
          </div>
        </div>
        <div className="flex justify-content-center surface-card shadow-2 p-3 border-round mt-2">
          <div className="flex flex-column w-6">
            <div className="flex flex-column ">
              <div className="flex flex-column">
                <div className="flex font-medium text-sm mb-2">
                  From Account
                </div>
                <div className="flex">
                  <Dropdown
                    value={selectedAccount}
                    onChange={(e) => setSelectedAccount(e.value)}
                    options={account}
                    optionLabel="name"
                    placeholder="Select Account"
                    className="w-full"
                  />
                </div>
              </div>
              <div className="flex flex-column mt-4">
                <div className="flex font-medium text-sm mb-2">To Account</div>
                <div className="flex">
                  <Dropdown
                    value={selectedAccount}
                    onChange={(e) => setSelectedAccount(e.value)}
                    options={account}
                    optionLabel="name"
                    placeholder="Select Account"
                    className="w-full"
                  />
                </div>
              </div>

              <div className="flex flex-column mt-4">
                <div className="flex font-medium text-sm mb-2">
                  Transfer Amount
                </div>
                <div className="flex">
                  <InputNumber
                    id="TransferAmount"
                    type="number"
                    value={transferAmount}
                    onChange={(e) => setTransferAmount(e.target.value)}
                    className="w-full mb-3"
                  />
                </div>
              </div>

              <Button
                label="Submit"
                className="w-full mt-4 mb-4"
                style={{ backgroundColor: "#00afab" }}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
