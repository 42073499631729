import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Menu } from "primereact/menu";
import { useData } from "../../context/DataContext";
import { NewOrderMobileDialog } from "../Dialog/NewOrderMobileDialog";
import "./mobileTopHeader.css";

export function MobileTopHeader({ setSidebarMenu, setNewOrderDialogVisible }) {
  const {
    setShowChart,
    setAreaChartShow,
    setTraidChartShow,
    setTimeSpan,
    setMultiplier,
    setComponentShow,
    setComponentShowFrom,
  } = useData();

  const navigate = useNavigate();

  const handleShowChart = () => {
    setShowChart(true);
  };

  const handleHideChart = () => {
    setShowChart(false);
  };

  const handleShowAreaChart = () => {
    setAreaChartShow(true);
    setTraidChartShow(false);
  };

  const handleShowTraidChart = () => {
    setTraidChartShow(true);
    setAreaChartShow(false);
  };

  const timePeriod = (period) => {
    setSelectedPeriod(period);
    switch (period) {
      case "D1":
        setTimeSpan("day");
        setMultiplier(1);
        break;
      case "W1":
        setTimeSpan("week");
        setMultiplier(1);
        break;
      case "M1":
        setTimeSpan("minute");
        setMultiplier(1);
        break;
      case "M5":
        setTimeSpan("minute");
        setMultiplier(5);
        break;
      case "M15":
        setTimeSpan("minute");
        setMultiplier(15);
        break;
      case "M30":
        setTimeSpan("minute");
        setMultiplier(30);
        break;
      case "H1":
        setTimeSpan("hour");
        setMultiplier(1);
        break;
      case "H4":
        setTimeSpan("hour");
        setMultiplier(4);
        break;
      case "MN":
        setTimeSpan("month");
        setMultiplier(1);
        break;
      default:
        break;
    }
  };

  const menuLeftCharts = useRef(null);
  const charts = [
    {
      code: "ShowTraidChart",
      icon: "pi pi-sliders-v",
      onClick: handleShowTraidChart,
    },
    {
      code: "ShowAreaChart",
      icon: (
        <i
          className="fa-solid fa-chart-line"
          style={{ fontSize: "16px", color: "var(--surface-800)" }}
        ></i>
      ),
      onClick: handleShowAreaChart,
    },
  ];
  const [selectedChart, setSelectedChart] = useState(charts[0]);
  const handleChartClick = (chart) => {
    setSelectedChart(chart);
    chart.onClick();
  };

  const menuLeftShownCharts = useRef(null);
  const shownCharts = [
    {
      code: "ShowChart",
      icon: (
        <i
          className="fa-solid fa-chart-column"
          style={{ fontSize: "16px", color: "var(--surface-800)" }}
        ></i>
      ),
      onClick: handleShowChart,
    },
    {
      code: "HideChart",
      icon: (
        <i
          className="fa-solid fa-chart-column"
          style={{ fontSize: "16px", color: "var(--surface-600)" }}
        ></i>
      ),
      onClick: handleHideChart,
    },
  ];
  const [shownChart, setShownChart] = useState(shownCharts[0]);
  const handleShownChartsClick = (Shownchart) => {
    setShownChart(Shownchart);
    Shownchart.onClick();
  };

  const menuLeftPeriod = useRef(null);
  const periodValues = [
    { name: "M1", code: "M1" },
    { name: "M5", code: "M5" },
    { name: "M15", code: "M15" },
    { name: "M30", code: "M30" },
    { name: "H1", code: "H1" },
    { name: "H4", code: "H4" },
    { name: "D1", code: "D1" },
    { name: "W1", code: "W1" },
    { name: "MN", code: "MN" },
  ];

  const [selectedPeriod, setSelectedPeriod] = useState("H1");
  const handlePeriodClick = (period) => {
    setSelectedPeriod(period.name);
    timePeriod(period.name);
  };

  return (
    <div className="flex flex-row flex-wrap border-bottom-2 text-400 surface-ground">
      <div className="flex align-items-center justify-content-center">
        <Button
          icon={
            <i
              className="fa-solid fa-bars"
              style={{ fontSize: "16px", color: "var(--surface-800)" }}
            ></i>
          }
          severity="secondary"
          style={{ boxShadow: "none" }}
          text
          onClick={() => setSidebarMenu((prev) => !prev)} // Toggle sidebar menu
        />
      </div>
      <div className="border-right-2 border-300"></div>
      <div className="card flex justify-content-center">
        <Menu
          model={charts.map((chart) => ({
            icon: chart.icon,
            command: () => handleChartClick(chart),
          }))}
          popup
          ref={menuLeftCharts}
          id="popup_menu_left"
        />
        <Button
          icon={selectedChart.icon}
          severity="secondary"
          onClick={(event) => menuLeftCharts.current.toggle(event)}
          aria-controls="popup_menu_left"
          aria-haspopup
          text
          raised
        />
      </div>

      <div
        className="border-right-2 border-300"
        style={{ marginTop: "7px", marginBottom: "7px" }}
      ></div>

      <div className="card flex justify-content-center">
        <Menu
          model={shownCharts.map((shownChart) => ({
            icon: shownChart.icon,
            command: () => handleShownChartsClick(shownChart),
          }))}
          popup
          ref={menuLeftShownCharts}
          id="popup_menu_left"
        />
        <Button
          icon={shownChart.icon}
          severity="secondary"
          onClick={(event) => menuLeftShownCharts.current.toggle(event)}
          aria-controls="popup_menu_left"
          aria-haspopup
          text
          raised
        />
      </div>

      <div
        className="border-right-2 border-300"
        style={{ marginTop: "7px", marginBottom: "7px" }}
      ></div>

      <div className="card flex justify-content-center customclass">
        <Menu
          model={periodValues.map((period) => ({
            label: period.name,
            command: () => handlePeriodClick(period),
          }))}
          popup
          ref={menuLeftPeriod}
          id="popup_menu_period"
        />
        <Button
          label={selectedPeriod}
          severity="secondary"
          onClick={(event) => menuLeftPeriod.current.toggle(event)}
          aria-controls="popup_menu_period"
          aria-haspopup
          text
          raised
        />
      </div>

      <div
        className="border-right-2 border-300"
        style={{ marginTop: "7px", marginBottom: "7px" }}
      ></div>

      <div className="flex align-items-center justify-content-center customclass">
        <Button
          className="flex flex-row"
          style={{ gap: "5px", boxShadow: "none" }}
          severity="secondary"
          // onClick={() => setNewOrderDialogVisible(true)}
          onClick={() => {
            setComponentShow("NewOrderMobileDialog");
            setComponentShowFrom("chart");
          }}
          text
        >
          <i
            className="fa-solid fa-money-bill"
            style={{ fontSize: "16px", color: "var(--surface-800)" }}
          ></i>
        </Button>
      </div>

      <div
        className="border-right-2 border-300"
        style={{ marginTop: "7px", marginBottom: "7px" }}
      ></div>

      <div className="flex align-items-center justify-content-center">
        <Button
          icon={
            <i
              className="fa-solid fa-bacon"
              style={{ fontSize: "16px", color: "var(--surface-800)" }}
            ></i>
          }
          severity="secondary"
          style={{ boxShadow: "none" }}
          text
        />
      </div>

      <div
        className="flex align-items-center justify-content-center"
        style={{ position: "absolute", right: "0px" }}
      >
        <Button
          severity="secondary"
          style={{ boxShadow: "none" }}
          text
          className="flex align-items-center text-900 font-medium text-sm mr-1"
          onClick={() => navigate("/dashboard/home")}
        >
          <div className="flex flex-row align-items-center justify-content-center">
            <span
              className="text-900 align-items-center justify-content-center"
              style={{ marginRight: "5px" }}
            >
              Dashboard
            </span>
            <i
              className="fa-solid fa-chalkboard-user"
              style={{
                fontSize: "16px",
                color: "var(--surface-800)",
              }}
            ></i>
          </div>
        </Button>
      </div>
    </div>
  );
}
