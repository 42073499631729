import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import { useData } from "../../../context/DataContext";
import logoImage from "../../../Photo/Tycoon.png";
import "./DashboardMenubar.css";

export function DashboardMenubar() {
  const [activeItem, setActiveItem] = useState(null);
  const navigate = useNavigate();
  const { setDashboardMenuBar, isMobile } = useData();
  const [isAdminUser, setIsAdminUser] = useState(null);

  const handleItemClick = (item) => {
    setActiveItem(item);
    // Navigate to the corresponding page when an item is clicked
    switch (item) {
      case "home":
        navigate(`/dashboard/${item}`);
        setDashboardMenuBar(false);
        break;
      case "users":
        navigate(`/dashboard/${item}`);
        setDashboardMenuBar(false);
        break;
      case "user_deposit_requests":
        navigate(`/dashboard/requests/${item}`);
        setDashboardMenuBar(false);
        break;
      case "user_withdraw_requests":
        navigate(`/dashboard/requests/${item}`);
        setDashboardMenuBar(false);
        break;
      case "user_closs_records":
        navigate(`/dashboard/requests/${item}`);
        setDashboardMenuBar(false);
        break;
      case "admin_accounts":
        navigate(`/dashboard/${item}`);
        setDashboardMenuBar(false);
        break;
      case "deposit_options":
        navigate(`/dashboard/${item}`);
        setDashboardMenuBar(false);
        break;
      case "deposit_requests":
        navigate(`/dashboard/${item}`);
        setDashboardMenuBar(false);
        break;
      case "withdraw_options":
        navigate(`/dashboard/${item}`);
        setDashboardMenuBar(false);
        break;
      case "withdraw_requests":
        navigate(`/dashboard/${item}`);
        setDashboardMenuBar(false);
        break;
      case "Policies":
        navigate(`/dashboard/${item}`);
        setDashboardMenuBar(false);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    const storedIsAdminUser = localStorage.getItem("isAdminUser");
    if (storedIsAdminUser !== null) {
      setIsAdminUser(JSON.parse(storedIsAdminUser));
    }
  }, []);

  const btnRef2 = useRef(null);
  const btnRef3 = useRef(null);
  const btnRef4 = useRef(null);
  return (
    <div
      className="w-full surface-200"
      style={{
        maxWidth: !isMobile ? "16rem" : "",
        width: "100%",
        // marginTop: "10rem",
        overflowY: "auto", // Make the left div scrollable
      }}
    >
      <div className="flex align-items-center justify-content-center mb-4">
        <img
          src={logoImage}
          alt="Description of the image"
          style={{ width: "120px", marginBottom: "1rem", marginTop: "1rem" }}
        />
      </div>
      <ul className="list-none pr-2 pl-0 m-0">
        <li>
          <div
            className={`p-ripple flex align-items-center text-800 cursor-pointer h-3rem ${
              activeItem === "home" ? "text-cyan-400" : ""
            }`}
            onClick={() => handleItemClick("home")}
          >
            {activeItem === "home" ? (
              <div
                className=" h-3rem bg-primary mr-2"
                style={{ width: "4px" }}
              ></div>
            ) : (
              <div style={{ width: "10px" }}></div>
            )}
            <i className="pi pi-objects-column ml-3"></i>
            <span className="font-medium text-sm ml-3 ">Dashboard</span>
            <Ripple />
          </div>
        </li>
      </ul>

      {!isAdminUser && (
        <ul className="list-none pr-2 pl-0 m-0">
          <li>
            <StyleClass
              nodeRef={btnRef2}
              selector="@next"
              enterClassName="hidden"
              enterActiveClassName="slidedown"
              leaveToClassName="hidden"
              leaveActiveClassName="slideup"
            >
              <div
                ref={btnRef2}
                className={`p-ripple flex align-items-center justify-content-between text-800 cursor-pointer ${
                  (activeItem === "Deposit" ||
                    activeItem === "deposit_options" ||
                    activeItem === "deposit_requests") &&
                  "text-cyan-400"
                }`}
                onClick={() => handleItemClick("Deposit")}
              >
                <div className="flex justify-content-start align-items-center h-3rem ">
                  {activeItem === "Deposit" ||
                  activeItem === "deposit_options" ||
                  activeItem === "deposit_requests" ? (
                    <div
                      className=" h-3rem bg-primary mr-2"
                      style={{ width: "4px" }}
                    ></div>
                  ) : (
                    <div style={{ width: "10px" }}></div>
                  )}
                  <i className="pi pi-sign-in ml-3"></i>
                  <span className="font-medium text-sm ml-3">Deposit</span>
                </div>
                <i
                  className="pi pi-chevron-down"
                  style={{ fontSize: ".875rem" }}
                ></i>
                <Ripple />
              </div>
            </StyleClass>
            <ul className="list-none p-0 m-0 overflow-hidden">
              <li>
                <a
                  className={`p-ripple flex align-items-center cursor-pointer mt-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${
                    activeItem === "deposit_options" ? "text-cyan-400" : ""
                  }`}
                  style={{ marginLeft: "60px" }}
                  onClick={() => handleItemClick("deposit_options")}
                >
                  <span className="font-medium text-sm">Deposit Options</span>
                  <Ripple />
                </a>
              </li>
              <li>
                <a
                  className={`p-ripple flex align-items-center cursor-pointer mt-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${
                    activeItem === "deposit_requests" ? "text-cyan-400" : ""
                  }`}
                  style={{ marginLeft: "60px" }}
                  onClick={() => handleItemClick("deposit_requests")}
                >
                  <span className="font-medium text-sm">
                    Deposite Requests History
                  </span>
                  <Ripple />
                </a>
              </li>
            </ul>
          </li>
        </ul>
      )}

      {!isAdminUser && (
        <ul className="list-none pr-2 pl-0 m-0 mt-3">
          <li>
            <StyleClass
              nodeRef={btnRef3}
              selector="@next"
              enterClassName="hidden"
              enterActiveClassName="slidedown"
              leaveToClassName="hidden"
              leaveActiveClassName="slideup"
            >
              <div
                ref={btnRef3}
                className={`p-ripple p-1 flex align-items-center justify-content-between text-800 cursor-pointer ${
                  (activeItem === "Withdraw" ||
                    activeItem === "withdraw_options" ||
                    activeItem === "withdraw_requests") &&
                  "text-cyan-400"
                }`}
                onClick={() => handleItemClick("Withdraw")}
              >
                <div className="flex justify-content-start align-items-center h-3rem">
                  {activeItem === "Withdraw" ||
                  activeItem === "withdraw_options" ||
                  activeItem === "withdraw_requests" ? (
                    <div
                      className=" h-3rem bg-primary mr-2"
                      style={{ width: "4px" }}
                    ></div>
                  ) : (
                    <div style={{ width: "10px" }}></div>
                  )}
                  <i className="pi pi-sign-in ml-3"></i>
                  <span className="font-medium text-sm ml-3">Withdraw</span>
                </div>
                <i
                  className="pi pi-chevron-down"
                  style={{ fontSize: ".875rem" }}
                ></i>
                <Ripple />
              </div>
            </StyleClass>
            <ul className="list-none p-0 m-0 overflow-hidden">
              <li>
                <a
                  className={`p-ripple flex align-items-center cursor-pointer mt-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${
                    activeItem === "withdraw_options" ? "text-cyan-400" : ""
                  }`}
                  style={{ marginLeft: "60px" }}
                  onClick={() => handleItemClick("withdraw_options")}
                >
                  <span className="font-medium text-sm">Withdraw Options</span>
                  <Ripple />
                </a>
              </li>

              <li>
                <a
                  className={`p-ripple flex align-items-center cursor-pointer mt-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${
                    activeItem === "withdraw_requests" ? "text-cyan-400" : ""
                  }`}
                  style={{ marginLeft: "60px" }}
                  onClick={() => handleItemClick("withdraw_requests")}
                >
                  <span className="font-medium text-sm">
                    Withdraw Requests History
                  </span>
                  <Ripple />
                </a>
              </li>
            </ul>
          </li>
        </ul>
      )}

      {isAdminUser && (
        <ul className="list-none pr-2 pl-0 m-0">
          <li>
            <div
              className={`p-ripple flex align-items-center text-800 cursor-pointer h-3rem ${
                activeItem === "admin_accounts" ? "text-cyan-400" : ""
              }`}
              onClick={() => handleItemClick("admin_accounts")}
            >
              {activeItem === "admin_accounts" ? (
                <div
                  className=" h-3rem bg-primary mr-2"
                  style={{ width: "4px" }}
                ></div>
              ) : (
                <div style={{ width: "10px" }}></div>
              )}
              <i class="fa-solid fa-money-check-dollar ml-3"></i>{" "}
              <span className="font-medium text-sm ml-3">Admin Accounts</span>
              <Ripple />
            </div>
          </li>
        </ul>
      )}

      {isAdminUser && (
        <ul className="list-none pr-2 pl-0 m-0">
          <li>
            <div
              className={`p-ripple flex align-items-center text-800 cursor-pointer h-3rem ${
                activeItem === "users" ? "text-cyan-400" : ""
              }`}
              onClick={() => handleItemClick("users")}
            >
              {activeItem === "users" ? (
                <div
                  className=" h-3rem bg-primary mr-2"
                  style={{ width: "4px" }}
                ></div>
              ) : (
                <div style={{ width: "10px" }}></div>
              )}
              <i className="fa-solid fa-users ml-3"></i>
              <span className="font-medium text-sm ml-3">Users</span>
              <Ripple />
            </div>
          </li>
        </ul>
      )}

      {isAdminUser && (
        <ul className="list-none pr-2 pl-0 m-0">
          <li>
            <StyleClass
              nodeRef={btnRef4}
              selector="@next"
              enterClassName="hidden"
              enterActiveClassName="slidedown"
              leaveToClassName="hidden"
              leaveActiveClassName="slideup"
            >
              <div
                ref={btnRef4}
                className={`p-ripple flex align-items-center justify-content-between text-800 cursor-pointer h-3rem ${
                  (activeItem === "user_deposit_requests" ||
                    activeItem === "user_closs_records" ||
                    activeItem === "user_withdraw_requests") &&
                  "text-cyan-400"
                }`}
                onClick={() => handleItemClick("user_deposit_requests")}
              >
                <div className="flex justify-content-start align-items-center ">
                  {activeItem === "user_deposit_requests" ||
                  activeItem === "user_closs_records" ||
                  activeItem === "user_withdraw_requests" ? (
                    <div
                      className=" h-3rem bg-primary mr-2"
                      style={{ width: "4px" }}
                    ></div>
                  ) : (
                    <div style={{ width: "10px" }}></div>
                  )}
                  <i class="fa-solid fa-bell ml-3"></i>
                  <span className="font-medium text-sm ml-3">
                    User Requests
                  </span>
                </div>
                {/* <div>
                  <i class="fa-solid fa-bell"></i>
                  <span className="font-medium text-sm ml-3">
                    User Requests
                  </span>
                </div> */}
                <i
                  className="pi pi-chevron-down"
                  style={{ fontSize: ".875rem" }}
                ></i>
                <Ripple />
              </div>
            </StyleClass>

            <ul className="list-none p-0 m-0 overflow-hidden">
              <li>
                <a
                  className={`p-ripple flex align-items-center cursor-pointer mt-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${
                    activeItem === "user_deposit_requests"
                      ? "text-cyan-400"
                      : ""
                  }`}
                  onClick={() => handleItemClick("user_deposit_requests")}
                  style={{ marginLeft: "60px" }}
                >
                  <span className="font-medium text-sm">
                    User Deposit Requests
                  </span>
                  <Ripple />
                </a>
              </li>
              <li>
                <a
                  className={`p-ripple flex align-items-center cursor-pointer mt-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${
                    activeItem === "user_withdraw_requests"
                      ? "text-cyan-400"
                      : ""
                  }`}
                  onClick={() => handleItemClick("user_withdraw_requests")}
                  style={{ marginLeft: "60px" }}
                >
                  <span className="font-medium text-sm">
                    User Withdraw Requests
                  </span>
                  <Ripple />
                </a>
              </li>
              <li>
                <a
                  className={`p-ripple flex align-items-center cursor-pointer mt-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full ${
                    activeItem === "user_closs_records" ? "text-cyan-400" : ""
                  }`}
                  onClick={() => handleItemClick("user_closs_records")}
                  style={{ marginLeft: "60px" }}
                >
                  <span className="font-medium text-sm">
                    User Clossed Records
                  </span>
                  <Ripple />
                </a>
              </li>
            </ul>
          </li>
        </ul>
      )}

      <div className="legal-terms">
        <ul className="list-none p-2 m-0">
          <li>
            <div
              className="p-ripple p-1 flex align-items-center text-800 cursor-pointer text-cyan-400"
              onClick={() => handleItemClick("Policies")}
            >
              <i className="fa-solid fa-table-columns"></i>
              <span className="font-medium text-sm ml-3">
                Legal Terms and Policies
              </span>
              <Ripple />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
