import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { useData } from "../../../context/DataContext";
import { NewBankAccountDialog } from "../../Dialog/NewBankAccountDialog";
import axios from "axios";

export function AdminAcountsPage() {
  const { isMobile, addNewAccountUpdate } = useData();
  const [newBankAccountDialogIsVisable, setNewBankAccountDialogIsVisable] =
    useState(false);
  const [accounts, setAccounts] = useState([]);

  const fetchAccount = async () => {
    try {
      const response = await axios.get(
        "https://makassab.com/trading_platform/backend/dashboard/admin/getPaymentGetways.php"
      );
      if (response.data.status === "success") {
        setAccounts(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching profile details:", error);
    }
  };
  useEffect(() => {
    fetchAccount();
  }, [addNewAccountUpdate]);

  const onRowEditComplete = async (e) => {
    let { newData } = e;
    try {
      const formData = new FormData();
      formData.append("id", newData.id);
      formData.append("isActive", newData.isActive); // or convert to string: formData.append("isActive", "1");

      const response = await axios.post(
        "https://makassab.com/trading_platform/backend/dashboard/admin/editStatusPayments.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure the request is sent as form-data
          },
        }
      );
      if (response.data.status === "success") {
        fetchAccount();
      }
    } catch (error) {
      console.log("API issue");
    }
  };

  // const textEditor = (options) => {
  //   return (
  //     <InputText
  //       type="text"
  //       style={{
  //         fontSize: isMobile ? "10px" : "14px",
  //         width: isMobile ? "130px" : "100%",
  //         height: isMobile ? "25px" : "100%",
  //       }}
  //       value={options.value}
  //       onChange={(e) => options.editorCallback(e.target.value)}
  //     />
  //   );
  // };

  const ActivationBodyTemplate = (rowData) => {
    return rowData.isActive === "1" ? "Active" : "Inactive";
  };

  const ActivationEditor = (options) => {
    return (
      <InputSwitch
        style={{
          width: isMobile ? "45px" : "",
          height: isMobile ? "21px" : "",
        }}
        checked={options.value === "1"} // Toggle based on isActive value (1 for Active, 0 for Inactive)
        onChange={(e) => options.editorCallback(e.value ? "1" : "0")} // Store "1" for Active and "0" for Inactive
      />
    );
  };

  const allowEdit = (rowData) => {
    return rowData.name !== "Blue Band";
  };

  return (
    <div className={`flex flex-column w-full ${isMobile ? "p-6" : "p-8"}`}>
      <NewBankAccountDialog
        newBankAccountDialogIsVisable={newBankAccountDialogIsVisable}
        setNewBankAccountDialogIsVisable={setNewBankAccountDialogIsVisable}
      />
      <div class="flex justify-content-end">
        <div class="flex align-items-center justify-content-center mb-2">
          <Button
            label="Add Account"
            severity="binary"
            outlined
            style={{ fontSize: isMobile ? "10px" : "14px", boxShadow: "none" }}
            onClick={() => setNewBankAccountDialogIsVisable(true)}
          />
        </div>
      </div>
      <DataTable
        value={accounts}
        editMode="row"
        dataKey="id"
        size={"small"}
        emptyMessage=" "
        className="font-normal text-sm text-900"
        onRowEditComplete={onRowEditComplete}
      >
        <Column
          field="id"
          header="ID"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>

        <Column
          field="name"
          header="Account Name"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        <Column
          field="number"
          header="Account Number"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        <Column
          field="moreInfo"
          header="More Info"
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        <Column
          field="isActive"
          header="Account Status"
          body={ActivationBodyTemplate} // Custom body to display Active/Inactive
          editor={(options) => ActivationEditor(options)} // Editor for toggling Active/Inactive
          style={{ fontSize: isMobile ? "10px" : "12px" }}
        ></Column>
        <Column
          rowEditor={allowEdit}
          // headerStyle={{ width: "10%", minWidth: "8rem" }}
          bodyStyle={{ textAlign: "center" }}
        ></Column>
      </DataTable>
    </div>
  );
}
