import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useData } from "../../context/DataContext";
import axios from "axios";

export function NewUserPasswordAdminDialog({
  setNewUserPasswordAdminDialogIsVisable,
  newUserPasswordAdminDialogIsVisable,
  selectedUserId,
  selectedUserName,
  setChangePasswordAlert,
}) {
  const { serverURL, isMobile } = useData();
  const [newPasswordvalue, setNewPasswordValue] = useState("");

  const handleNewPassword = async () => {
    try {
      const formData = new FormData();
      formData.append("trader_id", selectedUserId);
      formData.append("newPassword", newPasswordvalue);
      const response = await axios.post(
        "https://makassab.com/trading_platform/backend/dashboard/admin/editTraderPassword.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure the request is sent as form-data
          },
        }
      );
      if (response.data.status === "success") {
        setChangePasswordAlert(1);
        setNewUserPasswordAdminDialogIsVisable(false);
      }
    } catch (error) {
      console.log("API issue");
    }
  };
  return (
    <div className="card flex justify-content-center">
      <Dialog
        // header="New Bank Account"
        visible={newUserPasswordAdminDialogIsVisable}
        // style={{ width: "50vw" }}
        onHide={() => setNewUserPasswordAdminDialogIsVisable(false)}
      >
        <div class="flex flex-column mt-3 w-full">
          <div
            class="flex align-items-center justify-content-start mb-3"
            // style={{ width: "100%" }}
          >
            <div class="flex align-items-center " style={{ width: "50%" }}>
              User Name:
            </div>
            <div
              class="flex align-items-center justify-content-center"
              // style={{ width: "50%" }}
            >
              {selectedUserName}
            </div>
          </div>
          <div
            class="flex align-items-center justify-content-start mb-3"
            // style={{ width: "100%" }}
          >
            <div class="flex align-items-center " style={{ width: "50%" }}>
              New Password:
            </div>
            <div
              class="flex align-items-center justify-content-center"
              // style={{ width: "50%" }}
            >
              <InputText
                value={newPasswordvalue}
                style={{ width: "100%" }}
                onChange={(e) => setNewPasswordValue(e.target.value)}
              />
            </div>
          </div>
          <Button
            label="OK"
            className="w-full mt-4"
            style={{ backgroundColor: "#00afab" }}
            onClick={handleNewPassword}
          />
        </div>
      </Dialog>
    </div>
  );
}
