import React from "react";

export function ProfilePage() {
  return (
    <div
      className="flex align-items-center justify-content-center font-bold border-round mt-4"
      style={{ width: "100%" }}
    >
      <div
        className="flex flex-column surface-card shadow-2 font-bold border-round"
        style={{ width: "40rem" }}
      >
        <div className="flex flex-row align-items-center justify-content-start mt-4 ml-5">
          <div className="flex align-items-center justify-content-center ">
            <h2>Amr</h2>
          </div>
          <div className="flex align-items-center justify-content-center ml-5">
            Personal Account
          </div>
        </div>
        <div className="flex align-items-center justify-content-start text-base ml-5 mt-4">
          <div className="flex align-items-center font-normal text-color-secondary	w-12rem">
            Email Address
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            amr@gmail.com
          </div>
        </div>
        <div className="flex align-items-center justify-content-start ml-5 mt-4">
          <div className="flex align-items-center font-normal text-color-secondary w-12rem">
            Phone Number
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            +972595997717
          </div>
        </div>
        <div className="flex align-items-center justify-content-start ml-5 mt-4">
          <div className="flex align-items-center font-normal text-color-secondary w-12rem">
            Country of residency
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            Palestinian
          </div>
        </div>
        <div className="flex align-items-center justify-content-start ml-5 mt-4">
          <div className="flex align-items-center font-normal text-color-secondary w-12rem">
            Date of Birth
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            16/6/2000
          </div>
        </div>
        <div className="flex align-items-center justify-content-start ml-5 mt-4 mb-4">
          <div className="flex align-items-center font-normal text-color-secondary w-12rem">
            Nationality
          </div>
          <div className="flex align-items-center justify-content-center font-medium ml-5">
            Palestinian
          </div>
        </div>
      </div>
    </div>
  );
}
