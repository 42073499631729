import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { useData } from "../../context/DataContext";

export function MobileDownHeaderMenu() {
  const { setComponentShow } = useData();
  return (
    <div class="flex justify-content-between border-top-2 text-400 surface-ground	">
      <div class="flex align-items-center justify-content-center">
        <Button
          icon={
            <i
              class="fa-regular fa-file-lines"
              style={{ fontSize: "20px", color: "var(--surface-800)" }}
            ></i>
          }
          style={{ boxShadow: "none" }}
          text
          onClick={() => {
            setComponentShow("priceTable");
          }}
        />
      </div>
      <div class="flex align-items-center justify-content-center">
        <Button
          icon="pi pi-sliders-v"
          style={{
            boxShadow: "none",
            fontSize: "25px",
            color: "var(--surface-800)",
          }}
          text
          onClick={() => {
            setComponentShow("chart");
          }}
        />
      </div>
      <div class="flex align-items-center justify-content-center">
        <Button
          icon="pi pi-align-left"
          style={{
            boxShadow: "none",
            fontSize: "25px",
            color: "var(--surface-800)",
          }}
          text
          onClick={() => {
            setComponentShow("tradingTable");
          }}
        />
      </div>
    </div>
  );
}
