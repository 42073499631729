import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { LoginPage } from "./components/LoginPage/LoginPage";
import { SignupPage } from "./components/SignupPage/SignupPage";
import { TradingPage } from "./components/TradingPage/TradingPage";
import { MobileTradingPage } from "./components/TradingPage/MobileTradingPage";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { HomePage } from "./components/Dashboard/HomePage/HomePage";
import { ProfilePage } from "./components/Dashboard/ProfilePage/ProfilePage";
import { ActivityAccountPage } from "./components/Dashboard/ActivityAccountPage/ActivityAccountPage";
import { DepositPage } from "./components/Dashboard/DepositPage/DepositPage";
import { WithdrawPage } from "./components/Dashboard/WithdrawPage/WithdrawPage";
import { UserPage } from "./components/Dashboard/UserPage/UserPage";
import { AdminAcountsPage } from "./components/Dashboard/AdminAcountsPage/AdminAcountsPage";
import { DepositRequestHistoryPage } from "./components/Dashboard/DepositPage/DepositRequestHistoryPage";
import { WithdrawRequestHistoryPage } from "./components/Dashboard/WithdrawPage/WithdrawRequestHistoryPage";
import { UserRequestDepositPage } from "./components/Dashboard/UserRequestDepositPage/UserRequestDepositPage";
import { UserRequestWithdrawPage } from "./components/Dashboard/UserRequestWithdrawPage/UserRequestWithdrawPage";
import { UserClossedRecords } from "./components/Dashboard/UserClossedRecords/UserClossedRecords";
import { useData } from "./context/DataContext";
import SessionRoute from "./components/CheckRoute/SessionRoute";
import "./styles.css";

const App = () => {
  const { isMobile, setIsMobile, login } = useData();
  const [isAdminUser, setIsAdminUser] = useState(null); // Initial value set to null
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const location = useLocation();

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize(); // Initial check
    window.addEventListener("resize", checkScreenSize); // Add event listener for resize

    return () => window.removeEventListener("resize", checkScreenSize); // Clean up event listener
  }, []);

  const renderTradingPage = () => {
    if (location.pathname === "/trading") {
      return isMobile ? <MobileTradingPage /> : <TradingPage />;
    }
    return null;
  };

  useEffect(() => {
    const storedIsAdminUser = localStorage.getItem("isAdminUser");
    if (storedIsAdminUser !== null) {
      setIsAdminUser(JSON.parse(storedIsAdminUser));
    } else {
      setIsAdminUser(false); // Default value if not found
    }

    setIsLoading(false); // Set loading to false after fetching the value
  }, [login]);

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading indicator while fetching isAdminUser
  }

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route
        path="/trading"
        element={<SessionRoute element={renderTradingPage()} />}
      />
      <Route path="/dashboard/" element={<Dashboard />}>
        <Route path="home" element={<HomePage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="activity_account" element={<ActivityAccountPage />} />
        {!isAdminUser && <Route path="deposit" element={<DepositPage />} />}
        <Route
          path="deposit_requests"
          element={<DepositRequestHistoryPage />}
        />
        <Route
          path="withdraw_requests"
          element={<WithdrawRequestHistoryPage />}
        />
        {!isAdminUser && <Route path="withdraw" element={<WithdrawPage />} />}
        <Route path="deposit_options" element={<DepositPage />} />
        <Route path="withdraw_options" element={<WithdrawPage />} />
        {isAdminUser && <Route path="users" element={<UserPage />} />}
        {isAdminUser && (
          <Route
            path="requests/user_deposit_requests"
            element={<UserRequestDepositPage />}
          />
        )}
        {isAdminUser && (
          <Route
            path="requests/user_withdraw_requests"
            element={<UserRequestWithdrawPage />}
          />
        )}
        {isAdminUser && (
          <Route
            path="requests/user_closs_records"
            element={<UserClossedRecords />}
          />
        )}
        {isAdminUser && (
          <Route path="admin_accounts" element={<AdminAcountsPage />} />
        )}
      </Route>
    </Routes>
  );
};

export default App;
