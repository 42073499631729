import { useEffect, useState } from "react";
import { useData } from "../../context/DataContext";

// Function to convert timestamp to Date
const convertTimestampToDate = (timestamp) => {
  return new Date(timestamp);
};

const fetchApi = async (
  quote,
  multiplier,
  timespan,
  startDate,
  endDate,
  symbolType
) => {
  // Convert startDate and endDate to UNIX timestamps for Crypto
  const cryptoStartDate =
    symbolType.name === "Crypto" ? new Date(startDate).getTime() : startDate;
  const cryptoEndDate =
    symbolType.name === "Crypto" ? new Date(endDate).getTime() : endDate;

  const timespanLetter = timespan.charAt(0); // Get first letter, e.g., "h" for "hour"

  const url =
    symbolType.name === "Forex"
      ? `https://api.polygon.io/v2/aggs/ticker/C:USD${quote}/range/${multiplier}/${timespan}/${startDate}/${endDate}?adjusted=true&sort=asc&limit=50000&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
      : symbolType.name === "Crypto"
      ? `https://api.binance.com/api/v3/uiKlines?symbol=${quote}USDT&interval=${multiplier}${timespanLetter}&limit=1000&startTime=${cryptoStartDate}&endTime=${cryptoEndDate}`
      : symbolType.name === "Metals"
      ? `https://api.polygon.io/v2/aggs/ticker/C:${quote}USD/range/${multiplier}/${timespan}/${startDate}/${endDate}?adjusted=true&sort=asc&limit=50000&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
      : `https://api.polygon.io/v2/aggs/ticker/${quote}/range/${multiplier}/${timespan}/${startDate}/${endDate}?adjusted=true&sort=asc&limit=50000&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`;

  const response = await fetch(url);
  return response.json();
};

const getStartDateBasedOnTimespan = (timespan) => {
  const endDate = new Date();
  let startDate;

  switch (timespan) {
    case "minute":
      startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      break;
    case "hour":
      startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      break;
    case "day":
      startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 2);
      break;
    case "month":
      startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 3);
      break;
    default:
      startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1); // Default to 3 months if timespan is unrecognized
  }

  return {
    start: startDate.toISOString().split("T")[0],
    end: endDate.toISOString().split("T")[0],
  };
};

export function useMarketData(updating = true) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { multiplier, timespan, base, quote, symbolType } = useData();

  const fetchData = async () => {
    const { start, end } = getStartDateBasedOnTimespan(timespan);

    try {
      const response = await fetchApi(
        quote,
        multiplier,
        timespan,
        start,
        end,
        symbolType
      );

      let fetchedData = null;
      if (symbolType.name === "Forex") {
        fetchedData = response.results.map((obj) => ({
          date: convertTimestampToDate(obj.t),
          open: obj.o,
          low: obj.l,
          high: obj.h,
          close: obj.c,
          volume: obj.v,
        }));
      } else if (symbolType.name === "Crypto") {
        // Ensure response is an array before calling .map
        if (Array.isArray(response)) {
          fetchedData = response.map((kline) => ({
            date: convertTimestampToDate(kline[0]), // Kline open time
            open: parseFloat(kline[1]), // Open price
            high: parseFloat(kline[2]), // High price
            low: parseFloat(kline[3]), // Low price
            close: parseFloat(kline[4]), // Close price
            volume: parseFloat(kline[5]), // Volume
          }));
        } else {
          console.error("Unexpected response format:", response);
        }
      } else if (symbolType.name === "Stocks") {
        fetchedData = response.results.map((obj) => ({
          date: convertTimestampToDate(obj.t),
          open: obj.o,
          low: obj.l,
          high: obj.h,
          close: obj.c,
          volume: obj.v,
        }));
      } else if (symbolType.name === "Metals") {
        fetchedData = response.results.map((obj) => ({
          date: convertTimestampToDate(obj.t),
          open: obj.o,
          low: obj.l,
          high: obj.h,
          close: obj.c,
          volume: obj.v,
        }));
      }

      setData(fetchedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error); // Catch any errors
      setLoading(false);
    }
  };

  useEffect(() => {
    setData([]); // Clear old data immediately
    setLoading(true);

    // Fetch data
    fetchData();

    // Set interval for fetching latest data if updating is true
    if (updating) {
      const interval = setInterval(() => {
        fetchData();
      }, 1000); // Fetch data every second

      // Cleanup interval on unmount or when updating changes
      return () => clearInterval(interval);
    }
  }, [base, quote, multiplier, timespan, symbolType]);

  return {
    data,
    loaded: !loading && data.length > 0,
    loading,
  };
}
