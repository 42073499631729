import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Toast } from "primereact/toast";
import { useData } from "../../../context/DataContext";
import axios from "axios";

export function UserRequestDepositPage() {
  const { serverURL, isMobile } = useData();
  const toast = useRef(null);
  const [requests, setRequests] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText onChange={onGlobalFilterChange} placeholder="Search" />
        </IconField>
      </div>
    );
  };

  const header = renderHeader();

  const fetchDepositRequests = async () => {
    try {
      const response = await axios.get(
        "https://makassab.com/trading_platform/backend/dashboard/admin/getPendingDeposite.php"
      );
      if (response.data.status === "success") {
        setRequests(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching profile details:", error);
    }
  };

  useEffect(() => {
    fetchDepositRequests();
  }, []);

  const approveButton = (rowData) => (
    <Button
      label="Approve"
      severity="success"
      outlined
      style={{
        fontSize: isMobile ? "10px" : "12px",
        height: isMobile ? "12px" : "25px",
        boxShadow: "none",
      }}
      onClick={async () => {
        try {
          const response = await axios.post(
            "https://makassab.com/trading_platform/backend/dashboard/admin/editStatusDeposite.php",
            {
              deposites_id: rowData.id,
              status: 1,
            }
          );
          if (response.data.status === "success") {
            toast.current.show({
              severity: "success",
              summary: "success",
              detail: `Deposite request# ${rowData.id} approved successfully`,
            });
            fetchDepositRequests();
          }
        } catch (error) {
          console.log("API error");
        }
      }}
    />
  );
  const rejectButton = (rowData) => (
    <Button
      label="Reject"
      severity="danger"
      outlined
      style={{
        fontSize: isMobile ? "10px" : "12px",
        height: isMobile ? "12px" : "25px",
        boxShadow: "none",
      }}
      onClick={async () => {
        try {
          const response = await axios.post(
            "https://makassab.com/trading_platform/backend/dashboard/admin/editStatusDeposite.php",
            {
              deposites_id: rowData.id,
              status: 2,
            }
          );
          if (response.data.status === "success") {
            toast.current.show({
              severity: "danger",
              summary: "danger",
              detail: `Deposite ${rowData.id} request rejected successfully`,
            });
            fetchDepositRequests();
          }
        } catch (error) {
          console.log("API error");
        }
      }}
    />
  );

  const statusBodyTemplate = (rowData) => {
    return rowData.status === "0" ? "Pending" : " ";
  };

  return (
    <div className={`flex flex-column w-full ${isMobile ? "p-6" : "p-8"}`}>
      <Toast ref={toast} />

      <DataTable
        value={requests}
        size={"small"}
        filters={filters}
        emptyMessage=" "
        header={header}
        className="font-normal text-sm text-900"
        tableStyle={{ maxHeight: "100vh" }}
        scrollable
        scrollHeight="100vh"
      >
        <Column
          field="id"
          header="Request #"
          style={{
            fontSize: isMobile ? "10px" : "14px",
          }}
        ></Column>
        <Column
          field="traders_id"
          header="Trader ID"
          style={{ fontSize: isMobile ? "10px" : "14px" }}
        ></Column>
        <Column
          field="paymentAccount"
          header="Payment Account"
          style={{ fontSize: isMobile ? "10px" : "14px" }}
        ></Column>
        <Column
          field="number"
          header="Admin Account"
          style={{ fontSize: isMobile ? "10px" : "14px" }}
        ></Column>
        <Column
          field="depositAmount"
          header="Deposit Amount"
          style={{ fontSize: isMobile ? "10px" : "14px" }}
        ></Column>
        <Column
          field="timeStamp"
          header="Time"
          style={{ fontSize: isMobile ? "10px" : "14px" }}
        ></Column>
        <Column
          field="status"
          header="Status"
          body={statusBodyTemplate}
          style={{ fontSize: isMobile ? "10px" : "14px" }}
        ></Column>
        <Column
          header="Approve"
          body={approveButton}
          style={{ fontSize: isMobile ? "10px" : "14px" }}
        ></Column>
        <Column
          header="Reject"
          body={rejectButton}
          style={{ fontSize: isMobile ? "10px" : "14px" }}
        ></Column>
      </DataTable>
    </div>
  );
}
