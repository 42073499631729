import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { useData } from "../../context/DataContext";
import { Toast } from "primereact/toast";
import logoImage from "../../Photo/Tycoon.png";
import axios from "axios";

export function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [signinStatus, setSigninStatus] = useState("");
  const { serverURL, setLogin } = useData();
  const location = useLocation();
  const [isValidSession, setIsValidSession] = useState(null); // Use null to indicate the loading state
  const toast = useRef(null);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetch(`${serverURL}/testSession.php`);
        const result = await response.json();

        if (result.status === "success") {
          setIsValidSession(true);
        } else {
          setIsValidSession(false);
        }
      } catch (error) {
        console.error("Error checking session:", error);
        setIsValidSession(false);
      }
    };

    checkSession();
  }, [serverURL]);

  useEffect(() => {
    if (isValidSession) {
      navigate(-1); // navigate to the previous page
    }
  }, [isValidSession, navigate]);

  const handleSignIn = async () => {
    try {
      const response = await axios.post(
        `${serverURL}/login.php`,
        {
          email: email,
          password: password,
        },
        { withCredentials: true }
      );
      if (response.data.status === "Success") {
        if (response.data.accountType === "0") {
          // setIsAdminUser(false);
          localStorage.setItem("isAdminUser", false);
        } else {
          // setIsAdminUser(true);
          localStorage.setItem("isAdminUser", true);
        }
        setSigninStatus("SignIn successful!");
        setLogin(true);
        toast.current.show({
          severity: "success",
          summary: "success",
          detail: "SignIn successful!",
        });
        setTimeout(() => {
          navigate("/dashboard/home");
        }, 1000);
      } else if (response.data.status === "Failure") {
        switch (true) {
          case !email:
            setSigninStatus("Please enter your email.");
            break;
          case !password:
            setSigninStatus("Please enter your password.");
            break;
          default:
            setSigninStatus(response.data.message);
            break;
        }
      } else if (response.data.status === "This account does not exist") {
        setSigninStatus(
          "This account does not exist, please contact with support team"
        );
      }
    } catch (error) {
      setSigninStatus("This account does not exist");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSignIn();
    }
  };

  return (
    <div
      className="flex flex-column p-7 align-items-center justify-content-center"
      style={{ height: "100vh" }}
      onKeyPress={handleKeyPress} // Add the onKeyPress event listener
    >
      <Toast ref={toast} />
      <div className="surface-card p-4 shadow-2 border-round lg:w-4">
        <div className="flex flex-column">
          <div className="flex flex-column align-items-center justify-content-center mb-4">
            <div className="flex align-items-center justify-content-center mb-4">
              <img
                src={logoImage}
                alt="Description of the image"
                style={{ width: "45%" }}
              />
            </div>
          </div>
          <div className="flex flex-column">
            <div className="flex flex-column align-items-center justify-content-center mb-6">
              <span className="text-900 text-center justify-content-center text-xl font-medium mb-2">
                Login to Your Account
              </span>
              <span
                className="text-600 justify-content-center text-sm font-medium line-height-3"
                style={{ width: "300px", textAlign: "center" }}
              >
                Manage your accounts and access insightful reports and technical
                analysis among many more features.
              </span>
            </div>
            <div className="flex flex-column">
              <label
                htmlFor="email"
                className="block text-color-secondary text-sm font-medium mb-2"
              >
                Email
              </label>
              <InputText
                id="email"
                type="text"
                placeholder="Email address"
                className="w-full mb-3"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label
                htmlFor="password"
                className="block text-color-secondary text-sm font-medium mb-2"
              >
                Password
              </label>
              <InputText
                id="password"
                type="password"
                placeholder="Password"
                className="w-full mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex justify-content-between mb-5">
              <div className="flex align-items-center">
                <Checkbox
                  id="rememberme"
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                  className="mr-2"
                />
                <label
                  htmlFor="rememberme"
                  className="block text-color-secondary text-sm font-medium mb-2"
                >
                  Remember me
                </label>
              </div>
              <a
                href="#"
                className="block font-medium text-sm no-underline ml-2 text-blue-500 text-right cursor-pointer"
              >
                Forgot your password?
              </a>
            </div>
            <Button
              label="Sign In"
              icon="pi pi-user"
              className="w-full"
              onClick={handleSignIn}
            />
            {signinStatus && (
              <div
                className={`mt-3 text-${
                  signinStatus.includes("successful") ? "green" : "red"
                }-500`}
              >
                {signinStatus}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-3">
        <span className="text-900 font-medium text-sm line-height-3">
          Don't have an account?
        </span>
        <Link
          to="/signup"
          className="font-medium no-underline ml-2 text-sm text-blue-500 cursor-pointer"
        >
          Create Live Account
        </Link>
      </div>
    </div>
  );
}
