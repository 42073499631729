import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";

export function ChangePasswordPage() {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [language, setLanguage] = useState("English");
  const [checkedByEmail, setCheckedByEmail] = useState(true);
  const [checkedByPost, setCheckedByPost] = useState(true);
  const [checkedByPhone, setCheckedByPhone] = useState(false);

  return (
    <div className="flex flex-column w-full p-8">
      <div className="flex flex-column border-round">
        <div className="flex justify-content-between flex-wrap">
          <div className="flex font-medium">My Settings</div>
          <div className="flex"></div>
          <div
            className="flex align-items-center cursor-pointer"
            onClick={() => {
              navigate("/dashboard/home");
            }}
          >
            <i className="fa-solid fa-angle-left"></i>
            <span className="font-medium ml-1 mb-1">Back</span>
          </div>
        </div>
        <div className="flex justify-content-center surface-card shadow-2 p-3 border-round mt-2">
          <div className="flex flex-column w-6">
            <div className="flex flex-column ">
              <div className="flex">Account Settings</div>
              <div className="flex flex-column mt-4">
                <label htmlFor="currentpassword" className="mb-2">
                  Password
                </label>
                <div className="flex justify-content-between flex-wrap mb-3">
                  <div className="flex align-items-center w-8">
                    <InputText
                      id="currentpassword"
                      type="password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      placeholder="Password"
                      className="w-full "
                    />
                  </div>
                  <div className="flex"></div>
                  <div className="flex align-items-center justify-content-center">
                    <Button label="Change" severity="secondary" outlined />
                  </div>
                </div>
              </div>
              {/* <div className="flex flex-column mt-2">
                <label htmlFor="communicationType" className="mb-2">
                  Communication Preferences
                </label>
                <div className="flex justify-content-between flex-wrap">
                  <div className="flex align-items-center justify-content-center  border-round m-2">
                    By email
                  </div>
                  <div className="flex"></div>
                  <div className="card flex justify-content-center">
                    <InputSwitch
                      checked={checkedByEmail}
                      onChange={(e) => setCheckedByEmail(e.value)}
                    />
                  </div>
                </div>
                <div className="flex justify-content-between flex-wrap">
                  <div className="flex align-items-center justify-content-center  border-round m-2">
                    By post
                  </div>
                  <div className="flex"></div>
                  <div className="card flex justify-content-center">
                    <InputSwitch
                      checked={checkedByPost}
                      onChange={(e) => setCheckedByPost(e.value)}
                    />
                  </div>
                </div>
                <div className="flex justify-content-between flex-wrap">
                  <div className="flex align-items-center justify-content-center  border-round m-2">
                    By phone
                  </div>
                  <div className="flex"></div>
                  <div className="card flex justify-content-center">
                    <InputSwitch
                      checked={checkedByPhone}
                      onChange={(e) => setCheckedByPhone(e.value)}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
