import React, { useState } from "react";
import { Button } from "primereact/button";
import { useData } from "../../context/DataContext";

export function PriceDialog({ visible }) {
  const [Num, setNum] = useState(0.01);

  const { selectedRow } = useData(); // Accessing selectedRow from context

  const handleNum = (status) => {
    if (status === "decrease") {
      if (Num - 0.01 > 0) {
        setNum(Num - 0.01);
      }
    } else {
      setNum(Num + 0.01);
    }
  };

  return (
    visible && (
      <div
        visible={visible}
        style={{
          position: "absolute",
          top: "20px",
          left: "10px",
          zIndex: "1000",
        }}
      >
        <div className="flex flex-row">
          <div className="flex flex-row">
            <div className=" flex align-items-center justify-content-center w-5rem bg-primary font-medium text-white">
              {selectedRow?.bid}
            </div>

            <Button
              className="flex align-items-center justify-content-center w-3rem bg-blue-600 text-white text-sm font-medium  border-none	"
              onClick={() => handleNum("decrease")}
            >
              SELL
            </Button>
          </div>

          <div
            className="flex flex-row"
            style={{
              borderTop: "0.5px solid var(--surface-500)",
              borderBottom: "0.5px solid var(--surface-500)",
            }}
          >
            <Button
              icon="pi pi-angle-up"
              className="flex align-items-center  w-2rem border-none bg-white"
              onClick={() => handleNum("increase")}
            />
            <div className="flex align-items-center justify-content-center  w-3rem ">
              {Num.toFixed(2)}
            </div>
            <Button
              icon="pi pi-angle-down"
              className="flex align-items-center  w-2rem  border-none bg-white"
              onClick={() => handleNum("decrease")}
            />
          </div>

          <div className="flex flex-row">
            <Button
              className="flex align-items-center justify-content-center w-3rem bg-blue-600 text-white text-sm font-medium border-none"
              onClick={() => handleNum("decrease")}
            >
              BUY
            </Button>
            <div className="flex align-items-center justify-content-center w-5rem bg-primary font-medium  text-white">
              {selectedRow?.ask}
            </div>
          </div>
        </div>
      </div>
    )
  );
}
