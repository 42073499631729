import React, { createContext, useContext, useState } from "react";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const serverURL = "https://makassab.com/trading_platform/backend";
  const [showChart, setShowChart] = useState(true);
  const [areaChartShow, setAreaChartShow] = useState(false);
  const [traidChartShow, setTraidChartShow] = useState(true);
  const [crosshairShow, setCrosshairShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [symbolType, setSymbolType] = useState({ name: "Forex", code: "F" });
  const [quote, setQuote] = useState(
    symbolType.name === "Forex"
      ? "AED"
      : symbolType.name === "Crypto"
      ? "BTC"
      : symbolType.name === "Metals"
      ? "XAG"
      : "A"
  );
  const [timespan, setTimeSpan] = useState("hour");
  const [multiplier, setMultiplier] = useState(1);
  const [addRecord, setAddRecord] = useState(1);
  const [deleteRecord, setDeleteRecord] = useState(1);
  const [deleteRecordWeb, setDeleteRecordWeb] = useState(1);
  const [login, setLogin] = useState(false);
  const [componentShow, setComponentShow] = useState("chart");
  const [componentShowFrom, setComponentShowFrom] = useState("chart");
  const [isMobile, setIsMobile] = useState(false);
  const [TotalBalance, setTotoalBalance] = useState(0);
  const [dashboardMenuBar, setDashboardMenuBar] = useState(false);
  const [addNewAccountUpdate, setAddNewAccountUpdata] = useState(1);
  const [FreeMarginPublic, setFreeMarginPublic] = useState(0);
  const [FreeMarginLevelPublic, setFreeMarginLevelPublic] = useState(0);

  return (
    <DataContext.Provider
      value={{
        symbolType,
        setSymbolType,
        FreeMarginPublic,
        setFreeMarginPublic,
        FreeMarginLevelPublic,
        setFreeMarginLevelPublic,
        TotalBalance,
        setTotoalBalance,
        serverURL,
        showChart,
        setShowChart,
        areaChartShow,
        setAreaChartShow,
        traidChartShow,
        setTraidChartShow,
        crosshairShow,
        setCrosshairShow,
        selectedRow,
        setSelectedRow,
        quote,
        setQuote,
        timespan,
        setTimeSpan,
        multiplier,
        setMultiplier,
        addRecord,
        setAddRecord,
        login,
        setLogin,
        componentShow,
        setComponentShow,
        componentShowFrom,
        setComponentShowFrom,
        deleteRecord,
        setDeleteRecord,
        isMobile,
        setIsMobile,
        deleteRecordWeb,
        setDeleteRecordWeb,
        dashboardMenuBar,
        setDashboardMenuBar,
        addNewAccountUpdate,
        setAddNewAccountUpdata,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
