import React, { useRef, useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Menu } from "primereact/menu";
import { useData } from "../../../context/DataContext";
import axios from "axios";

export function DashboardHeader({ firstName, lastName }) {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const { serverURL, setDashboardMenuBar, dashboardMenuBar, isMobile } =
    useData();
  const menuRight = useRef(null);
  const [isAdminUser, setIsAdminUser] = useState(null);

  useEffect(() => {
    const storedIsAdminUser = localStorage.getItem("isAdminUser");
    if (storedIsAdminUser !== null) {
      setIsAdminUser(JSON.parse(storedIsAdminUser));
    }
  }, []);

  const handleLogout = async () => {
    try {
      const response = await axios.post(`${serverURL}/logout.php`);

      if (response.data.status === "success") {
        // Clear user data
        // Navigate to login page
        localStorage.setItem("isAdminUser", false);
        navigate("/");
      } else {
        console.error("Failed to log out:", response.data);
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const items = [
    {
      label: "",
      items: [
        // {
        //   label: "My Documents",
        //   // command: () => navigate("/"), // Redirect to "/" on Logout
        // },
        // {
        //   label: "My Settings",
        //   command: () => navigate("change-password"),
        // },
        // {
        //   label: "Support",
        //   // command: () => navigate("/"), // Redirect to "/" on Logout
        // },
        {
          label: "LogOut",
          command: handleLogout,
        },
      ],
    },
  ];

  return (
    <div class="flex justify-content-between flex-wrap">
      <div class="flex align-items-center justify-content-center">
        {isMobile && (
          <Button
            icon="pi pi-align-justify"
            severity="secondary"
            style={{ color: "var(--surface-800)", boxShadow: "none" }}
            text
            onClick={() => {
              setDashboardMenuBar(!dashboardMenuBar);
            }}
          />
        )}
      </div>
      <div class="flex align-items-center justify-content-center"></div>
      <div class="flex flex-row align-items-center justify-content-center font-bold border-round p-3 mr-2">
        {!isAdminUser && (
          <Button
            severity="secondary"
            text
            aria-controls="popup_menu_right"
            aria-haspopup
            className="flex align-items-center text-900 font-medium text-sm"
            onClick={() => navigate("/trading")}
          >
            <i
              className="fa-solid fa-table text-cyan-400"
              style={{
                fontSize: "13px",
                position: "absolute",
                left: "3px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            ></i>

            <span className="text-cyan-400" style={{ marginLeft: "5px" }}>
              Web Terminal
            </span>
          </Button>
        )}

        <Button
          label={firstName + " " + lastName}
          text
          aria-controls="popup_menu_right"
          aria-haspopup
          className="flex align-items-center text-900 font-medium text-sm"
          onClick={(event) => menuRight.current.toggle(event)}
        >
          <i
            className={classNames("pi pi-chevron-down")}
            style={{
              fontSize: "10px",
              marginLeft: "5px",
              marginTop: "6px",
            }}
          ></i>
        </Button>
      </div>
      <Menu
        model={items}
        popup
        ref={menuRight}
        id="popup_menu_right"
        popupAlignment="right"
        style={{ padding: "8px" }}
      />
    </div>
  );
}
