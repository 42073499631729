import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

const SessionRoute = ({ element }) => {
  const [sessionStatus, setSessionStatus] = useState(null);
  const location = useLocation(); // Hook to get the current location

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetch(
          "https://makassab.com/trading_platform/backend/testSession.php"
        );
        const data = await response.json();
        if (data.status === "success") {
          setSessionStatus(true);
        } else {
          setSessionStatus(false);
        }
      } catch (error) {
        setSessionStatus(false);
      }
    };

    checkSession();
  }, []);

  if (sessionStatus === null) {
    return <div>Loading...</div>; // Show loading state while checking session
  }

  if (
    sessionStatus &&
    (location.pathname === "/" || location.pathname === "/signup")
  ) {
    return <Navigate to={-1} />; // Go back to the previous page if the user is trying to navigate to "/"
  }

  return sessionStatus ? element : <Navigate to="/" />;
};

export default SessionRoute;
