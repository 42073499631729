import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DashboardMenubar } from "./DashboardMenubar/DashboardMenubar";
import { Routes, Route } from "react-router-dom";
import { HomePage } from "./HomePage/HomePage";
import { AccountsPage } from "./AccountsPage/AccountsPage";
import { FundsPage } from "./FundsPage/FundsPage";
import { ProfilePage } from "./ProfilePage/ProfilePage";
import { ActivityAccountPage } from "./ActivityAccountPage/ActivityAccountPage";
import { ChangePasswordPage } from "./ChangePasswordPage/ChangePasswordPage";
import { DepositPage } from "./DepositPage/DepositPage";
import { WithdrawPage } from "./WithdrawPage/WithdrawPage";
import { TransferPage } from "./TransferPage/TransferPage";
import { UserPage } from "./UserPage/UserPage";
import { UserRequestDepositPage } from "./UserRequestDepositPage/UserRequestDepositPage";
import { DepositRequestHistoryPage } from "./DepositPage/DepositRequestHistoryPage";
import { WithdrawRequestHistoryPage } from "./WithdrawPage/WithdrawRequestHistoryPage";
import { UserRequestWithdrawPage } from "./UserRequestWithdrawPage/UserRequestWithdrawPage";
import { UserClossedRecords } from "./UserClossedRecords/UserClossedRecords";
import { AdminAcountsPage } from "./AdminAcountsPage/AdminAcountsPage";
import { DashboardHeader } from "./DashboardHeader/DashboardHeader";
import { useData } from "../../context/DataContext";
import axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";

export function Dashboard() {
  const { serverURL, isMobile, dashboardMenuBar } = useData();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [historyData, setHistoryData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [profileDetails, setProfileDetails] = useState(null);

  useEffect(() => {
    const fetchHistoryData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://makassab.com/trading_platform/backend/dashboard/customer/getTradesHistory.php"
        );
        setHistoryData(response.data.data);
      } catch (error) {
        console.error("Error fetching history data:", error);
        setLoading(false); // Error occurred, set loading to false
      } finally {
        setLoading(false); // Data fetched successfully, set loading to false
      }
    };

    fetchHistoryData();
  }, []);

  useEffect(() => {
    const fetchProfileDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://makassab.com/trading_platform/backend/dashboard/customer/getProfileDetails.php"
        );
        setProfileDetails(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching profile details:", error);
      } finally {
        setLoading(false); // Data fetched successfully, set loading to false
      }
    };

    fetchProfileDetails();
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loading) {
    return (
      <div className="card flex justify-content-center">
        <ProgressSpinner />
      </div>
    );
  }

  return (
    <div
      className="flex flex-row"
      style={{ height: "100vh", backgroundColor: "#f1f3f9    " }}
    >
      {isMobile ? (
        dashboardMenuBar ? (
          <DashboardMenubar />
        ) : (
          <div
            className="flex flex-column surface-200"
            style={{ overflowY: "auto", width: "100%", height: "100%" }}
          >
            <DashboardHeader
              firstName={profileDetails?.firstName}
              lastName={profileDetails?.lastName}
            />
            <div>
              <Routes>
                <Route path="menu" element={<DashboardMenubar />} />
                <Route
                  path="home"
                  element={
                    <HomePage
                      firstName={profileDetails?.firstName}
                      lastName={profileDetails?.lastName}
                      totalBalance={profileDetails?.totalBalance}
                      totalCredit={profileDetails?.totalCredit}
                      totalDeposit={profileDetails?.totalDeposite}
                      totalWithdrawals={profileDetails?.totalWithdrawals}
                      HistoryData={historyData}
                    />
                  }
                />
                <Route
                  path="activity_account"
                  element={<ActivityAccountPage />}
                />
                <Route path="accounts" element={<AccountsPage />} />
                <Route path="funds" element={<FundsPage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="deposit" element={<DepositPage />} />
                <Route
                  path="deposit_requests"
                  element={<DepositRequestHistoryPage />}
                />
                <Route path="withdraw" element={<WithdrawPage />} />
                <Route
                  path="withdraw_requests"
                  element={<WithdrawRequestHistoryPage />}
                />

                <Route
                  path="change-password"
                  element={<ChangePasswordPage />}
                />
                <Route path="transfers" element={<TransferPage />} />
                <Route path="deposit_options" element={<DepositPage />} />
                <Route path="withdraw_options" element={<WithdrawPage />} />
                <Route path="users" element={<UserPage />} />
                <Route
                  path="/requests/user_deposit_requests"
                  element={<UserRequestDepositPage />}
                />
                <Route
                  path="/requests/user_withdraw_requests"
                  element={<UserRequestWithdrawPage />}
                />
                <Route
                  path="/requests/user_closs_records"
                  element={<UserClossedRecords />}
                />
                <Route path="admin_accounts" element={<AdminAcountsPage />} />
              </Routes>
            </div>
          </div>
        )
      ) : (
        <>
          <DashboardMenubar />

          <div
            className="flex flex-column bg-white-alpha-20	"
            style={{ overflowY: "auto", width: "100%", height: "100%" }}
          >
            <DashboardHeader
              firstName={profileDetails?.firstName}
              lastName={profileDetails?.lastName}
            />
            <div>
              <Routes>
                <Route path="menu" element={<DashboardMenubar />} />
                <Route
                  path="home"
                  element={
                    <HomePage
                      firstName={profileDetails?.firstName}
                      lastName={profileDetails?.lastName}
                      totalBalance={profileDetails?.totalBalance}
                      totalCredit={profileDetails?.totalCredit}
                      totalDeposit={profileDetails?.totalDeposite}
                      totalWithdrawals={profileDetails?.totalWithdrawals}
                      HistoryData={historyData}
                    />
                  }
                />
                <Route
                  path="activity_account"
                  element={<ActivityAccountPage />}
                />
                <Route path="accounts" element={<AccountsPage />} />
                <Route path="funds" element={<FundsPage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="deposit" element={<DepositPage />} />
                <Route
                  path="deposit_requests"
                  element={<DepositRequestHistoryPage />}
                />

                <Route path="withdraw" element={<WithdrawPage />} />
                <Route
                  path="withdraw_requests"
                  element={<WithdrawRequestHistoryPage />}
                />
                <Route
                  path="change-password"
                  element={<ChangePasswordPage />}
                />
                <Route path="transfers" element={<TransferPage />} />
                <Route path="deposit_options" element={<DepositPage />} />
                <Route path="withdraw_options" element={<WithdrawPage />} />
                <Route path="users" element={<UserPage />} />
                <Route
                  path="/requests/user_deposit_requests"
                  element={<UserRequestDepositPage />}
                />
                <Route
                  path="/requests/user_withdraw_requests"
                  element={<UserRequestWithdrawPage />}
                />
                <Route
                  path="/requests/user_closs_records"
                  element={<UserClossedRecords />}
                />
                <Route path="admin_accounts" element={<AdminAcountsPage />} />
              </Routes>
            </div>
          </div>
        </>
      )}
      <div
        className="align-items-center justify-content-center"
        style={{ position: "fixed", bottom: "20px", right: "20px" }}
      >
        <Button
          className="align-items-center justify-content-center"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "20px",
            backgroundColor: "rgb(221, 221, 221)",
            border: "none",
          }}
          onClick={() => {
            window.open("https://wa.me/1234567890", "_blank");
          }}
        >
          <i
            class="fa-brands fa-whatsapp"
            style={{ fontSize: "35px", color: "green" }}
          ></i>
        </Button>
      </div>
    </div>
  );
}
