import React, { useState } from "react";
import { PriceChart } from "../Chart/PriceChart";
import { PriceTable } from "../Table/PriceTable";
import { SidebarMenu } from "../SidebarMenu/SidebarMenu";
import { TradeTable } from "../Table/TradeTable";
import { TopHeader } from "../TopHeader/TopHeader";
import { DataProvider } from "../../context/DataContext";
import { PriceDialog } from "../Dialog/PriceDialog";
import { NewOrderDialog } from "../Dialog/NewOrderDialog";

export function TradingPage() {
  const [number, setNumber] = useState(1);
  const [priceDialogVisible, setPriceDialogVisible] = useState(false);
  const [newOrderDialogVisible, setNewOrderDialogVisible] = useState(false);

  const handleClick = (num) => {
    if (number === num) {
      setNumber(null);
    } else {
      setNumber(num);
    }
  };

  return (
    <DataProvider>
      <div className="flex flex-row">
        <div className="flex" style={{ height: "100vh" }}>
          <SidebarMenu handleClick={handleClick} />
        </div>
        <div
          className="flex flex-column"
          style={{ height: "100vh", width: "100%" }}
        >
          <TopHeader
            setPriceDialogVisible={setPriceDialogVisible}
            setNewOrderDialogVisible={setNewOrderDialogVisible}
            priceDialogVisible={priceDialogVisible} // Pass the visibility state
          />

          <div
            className="flex flex-row border-bottom-1 surface-border"
            style={{ position: "relative", height: "70vh" }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
              className="flex"
            >
              <PriceDialog visible={priceDialogVisible} />
              <NewOrderDialog
                visible={newOrderDialogVisible}
                setVisible={setNewOrderDialogVisible}
              />
              <PriceChart setNewOrderDialogVisible={setNewOrderDialogVisible} />
            </div>
            <div
              style={{
                height: "100%",
                width: "30%",
              }}
              className="flex border-left-1 surface-border"
            >
              <PriceTable setNewOrderDialogVisible={setNewOrderDialogVisible} />
            </div>
          </div>
          <div style={{}} className="flex">
            {number === 1 && (
              <TradeTable setNewOrderDialogVisible={setNewOrderDialogVisible} />
            )}
          </div>
        </div>
      </div>
    </DataProvider>
  );
}
