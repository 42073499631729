import React from "react";
import { AccountBox } from "../AccountBox/AccountBox";

export function AccountsPage() {
  return (
    <div
      className="flex font-bold border-round"
      style={{ width: "100%", height: "100%", padding: "4rem" }}
    >
      <AccountBox></AccountBox>
    </div>
  );
}
