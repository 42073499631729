import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import { useData } from "../../context/DataContext";
import {
  discontinuousTimeScaleProviderBuilder,
  ema,
  Chart,
  ChartCanvas,
  CurrentCoordinate,
  BarSeries,
  CandlestickSeries,
  LineSeries,
  lastVisibleItemBasedZoomAnchor,
  XAxis,
  YAxis,
  CrossHairCursor,
  EdgeIndicator,
  MouseCoordinateX,
  MouseCoordinateY,
  ZoomButtons,
  withDeviceRatio,
  withSize,
} from "react-financial-charts";
import { useMarketData } from "./useMarketData";
import OHLCTooltip from "./OHLCTooltip";
import { ProgressSpinner } from "primereact/progressspinner"; // Import ProgressSpinner from PrimeReact

const axisStyles = {
  strokeStyle: "#383E55", // Color.GRAY
  strokeWidth: 2,
  tickLabelFill: "#9EAAC7", // Color.LIGHT_GRAY
  tickStrokeStyle: "#383E55",
  gridLinesStrokeStyle: "rgba(56, 62, 85, 0.5)", // Color.GRAY w Opacity
};

const coordinateStyles = {
  fill: "#383E55",
  textFill: "#FFFFFF",
};

const zoomButtonStyles = {
  fill: "#383E55",
  fillOpacity: 0.75,
  strokeWidth: 0,
  textFill: "#9EAAC7",
};

const crossHairStyles = {
  strokeStyle: "#9EAAC7",
};

// Default green/red colors for candlesticks
const openCloseColor = (d) => (d?.close > d?.open ? "#26a69a" : "#ef5350");

const yExtentsCalculator = ({ plotData }) => {
  let min;
  let max;
  for (const { low, high } of plotData) {
    if (min === undefined) min = low;
    if (max === undefined) max = high;
    if (low !== undefined && min > low) min = low;
    if (high !== undefined && max < high) max = high;
  }
  if (min === undefined) min = 0;
  if (max === undefined) max = 0;

  const padding = (max - min) * 0.1;
  return [min - padding, max + padding * 2];
};

const FinancialChart = ({
  dateTimeFormat,
  height,
  margin,
  priceDisplayFormat,
  ratio,
  width,
  setNewOrderDialogVisible,
}) => {
  const { data: initialData, loaded } = useMarketData();
  const [resetCount, setResetCount] = useState(0);
  const {
    showChart,
    areaChartShow,
    traidChartShow,
    crosshairShow,
    selectedRow,
    setSelectedRow,
    isMobile,
    quote,
    setComponentShowFrom,
    setComponentShow,
  } = useData();

  const prevBidRef = useRef(null);

  const [bidBackground, setBidBackground] = useState("#26a69a");

  useEffect(() => {
    if (selectedRow?.bid !== prevBidRef.current) {
      setBidBackground(
        selectedRow?.bid > prevBidRef.current ? "#26a69a" : "#ef5350"
      );
      prevBidRef.current = selectedRow?.bid;
    }
  }, [selectedRow?.bid]);

  const fetchDataValue = async () => {
    try {
      const response = await fetch(
        `https://api.polygon.io/v1/conversion/USD/${quote}?amount=100&precision=2&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
      );
      const responseData = await response.json();
      setSelectedRow((prevSelectedRow) => ({
        ...prevSelectedRow,
        ask: responseData.last?.ask.toFixed(5),
        bid: responseData.last?.bid.toFixed(5),
      }));
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { ask: null, bid: null };
    }
  };

  useEffect(() => {
    if (isMobile) {
      fetchDataValue();
    }
  }, [selectedRow]);

  const openCloseColor2 = () => {
    return bidBackground;
  };

  if (!loaded || !height || !ratio || !width) {
    return (
      <div style={{ position: "relative", height, width }}>
        <ProgressSpinner
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
    );
  }
  const timeDisplayFormat = timeFormat(dateTimeFormat);
  const xScaleProvider =
    discontinuousTimeScaleProviderBuilder().inputDateAccessor((d) => d?.date);
  const { data, xScale, xAccessor, displayXAccessor } =
    xScaleProvider(initialData);

  const min = xAccessor(data[Math.max(0, data.length - parseInt(width / 5))]);

  const max = xAccessor(data[data.length - 1]);

  const xExtents = [min, max + 1];

  const gridHeight = height - margin.top - margin.bottom;
  const barChartHeight = gridHeight / 5;
  const barChartOrigin = (_, h) => [0, h - barChartHeight];

  const handleDoubleClick = (event) => {
    if (isMobile) {
      setComponentShow("NewOrderMobileDialog");
      setComponentShowFrom("chart");
    } else {
      setNewOrderDialogVisible(true);
    }
  };

  // ChartCanvas is drawn from top to bottom
  return (
    <ChartCanvas
      height={height}
      ratio={ratio}
      width={width}
      margin={margin}
      seriesName={`Chart ${resetCount}`}
      data={data}
      xScale={xScale}
      xAccessor={xAccessor}
      displayXAccessor={displayXAccessor}
      xExtents={xExtents}
      zoomAnchor={lastVisibleItemBasedZoomAnchor}
      selectedRow={selectedRow} // Pass selectedRow as a prop
      onDoubleClick={handleDoubleClick} // Handle double-click
    >
      {/* Volume Chart */}
      {showChart && (
        <Chart
          id={1}
          height={barChartHeight}
          origin={barChartOrigin}
          yExtents={(d) => d?.volume}
        >
          <BarSeries
            fillStyle={(d) =>
              d.close > d.open
                ? "rgba(38, 166, 154, 0.3)"
                : "rgba(239, 83, 80, 0.3)"
            }
            yAccessor={(d) => d?.volume}
          />
        </Chart>
      )}

      {/* Price Chart */}
      <Chart id={2} yExtentsCalculator={yExtentsCalculator}>
        <XAxis {...axisStyles} showGridLines />
        <MouseCoordinateX
          displayFormat={timeDisplayFormat}
          {...coordinateStyles}
        />
        <YAxis {...axisStyles} showGridLines />
        <MouseCoordinateY
          rectWidth={margin.right}
          displayFormat={priceDisplayFormat}
          {...coordinateStyles}
        />

        {/* YAxis close price highlight */}
        <EdgeIndicator
          itemType="last"
          rectWidth={margin.right}
          fill={openCloseColor2}
          lineStroke={openCloseColor2}
          displayFormat={priceDisplayFormat}
          fontSize={10} // Set the font size to 10px
          fontWeight="bold" // Make text bold
          yAccessor={(d) => (selectedRow ? selectedRow.bid : d?.close)}
        />

        {traidChartShow && <CandlestickSeries />}
        <OHLCTooltip
          origin={[8, 16]}
          textFill={openCloseColor}
          className="react-no-select"
        />
        <ZoomButtons
          onReset={() => setResetCount(resetCount + 1)}
          {...zoomButtonStyles}
        />
      </Chart>
      {crosshairShow && <CrossHairCursor {...crossHairStyles} />}
    </ChartCanvas>
  );
};

FinancialChart.propTypes = {
  dateTimeFormat: PropTypes.string,
  height: PropTypes.number,
  margin: PropTypes.object,
  priceDisplayFormat: PropTypes.func,
  ratio: PropTypes.number,
  width: PropTypes.number,
  setNewOrderDialogVisible: PropTypes.func.isRequired, // Added prop type
};

FinancialChart.defaultProps = {
  dateTimeFormat: "%d %b '%y \xa0 %H:%M",
  height: 0,
  margin: { left: 0, right: 48, top: 0, bottom: 24 },
  priceDisplayFormat: format(".5f"),
  ratio: 0,
  width: 0,
};

export const PriceChart = withSize({ style: { minHeight: 600 } })(
  withDeviceRatio()(FinancialChart)
);
