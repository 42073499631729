import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useData } from "../../context/DataContext";
import { Toast } from "primereact/toast";
import axios from "axios";

export function SignupPage() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [signupStatus, setSignupStatus] = useState("");
  const [countries, setCountries] = useState([]);
  const { serverURL } = useData();
  const toast = useRef(null);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${serverURL}/getCountries.php`);

        if (response.data.status === "success") {
          const fetchedCountries = response.data.data.map((country) => ({
            name: country.countryName,
            id: country.id,
            code: country.countryCode,
          }));
          setCountries(fetchedCountries);
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, [serverURL]);

  const handleSignUp = async () => {
    try {
      const response = await axios.post(`${serverURL}/registration.php`, {
        firstName: firstName,
        lastName: lastName,
        phoneNo: phoneNumber,
        email: email,
        password: password,
        country_id: selectedCountry ? selectedCountry.id : "",
      });

      if (response.data.status === "Success") {
        setSignupStatus(response.data.message);
        toast.current.show({
          severity: "success",
          summary: "success",
          detail: response.data.message,
        });
        setTimeout(() => {
          navigate("/");
        }, 1500);
      } else if (response.data.status === "This account does not exist") {
        setSignupStatus("This account does not exist.");
      } else {
        switch (true) {
          case !firstName:
            setSignupStatus("Please enter your first name.");
            break;
          case !lastName:
            setSignupStatus("Please enter your last name.");
            break;
          case !phoneNumber:
            setSignupStatus("Please enter your phone number.");
            break;
          case !email:
            setSignupStatus("Please enter your email address.");
            break;
          case !password:
            setSignupStatus("Please enter your password.");
            break;
          case !selectedCountry:
            setSignupStatus("Please select your country.");
            break;
          default:
            setSignupStatus(response.data.message);
            break;
        }
      }
    } catch (error) {
      console.error("Error during signup:", error);
      setSignupStatus("An error occurred. Please try again later.");
    }
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "18px" }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSignUp();
    }
  };

  return (
    <div
      className="flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
      onKeyPress={handleKeyPress} // Add the onKeyPress event listener
    >
      <Toast ref={toast} />
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">Sign Up</div>
        </div>
        <div>
          <label
            htmlFor="firstname"
            className="block text-900 font-medium mb-2"
          >
            First Name
          </label>
          <InputText
            id="firstname"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First name"
            className="w-full mb-3"
          />

          <label htmlFor="lastname" className="block text-900 font-medium mb-2">
            Last Name
          </label>
          <InputText
            id="lastname"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last name"
            className="w-full mb-3"
          />

          <label
            htmlFor="phoneNumber"
            className="block text-900 font-medium mb-2"
          >
            Phone Number
          </label>
          <InputText
            id="phoneNumber"
            type="number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Phone number"
            className="w-full mb-3"
          />

          <label htmlFor="email" className="block text-900 font-medium mb-2">
            Email
          </label>
          <InputText
            id="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
            className="w-full mb-3"
          />

          <label htmlFor="password" className="block text-900 font-medium mb-2">
            Password
          </label>
          <InputText
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="w-full mb-3"
          />

          <label htmlFor="country" className="block text-900 font-medium mb-2">
            Country
          </label>
          <Dropdown
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.value)}
            options={countries}
            optionLabel="name"
            placeholder="Select a Country"
            filter
            valueTemplate={selectedCountryTemplate}
            className="w-full mb-3"
          />

          <Button
            label="Sign Up"
            icon="pi pi-user"
            className="w-full"
            onClick={handleSignUp}
          />

          {signupStatus && (
            <div
              className={`mt-3 text-${
                signupStatus.includes("successful") ? "green" : "red"
              }-500`}
            >
              {signupStatus}
            </div>
          )}

          <div className="text-center mt-4">
            <span className="text-600 font-medium line-height-3">
              I have an account
            </span>
            <Link
              to="/"
              className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
