import React, { useState, useRef, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { useData } from "../../context/DataContext";
import "./deletedMobileDialog.css";

const useInterval = (callback, delay) => {
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);

  return intervalRef;
};

export function DeletedMobileDialog({
  visible,
  setVisible,
  setNewOrderDialogVisible,
  selectedItem, // Receive the selected item
  setSelectedItem,
}) {
  const {
    setComponentShow,
    setSelectedRow,
    deleteRecord,
    setDeleteRecord,
    TotalBalance,
    setComponentShowFrom,
  } = useData();
  // const [profitValue, setProfitValue] = useState();
  // const [isOpen, setIsOpen] = useState(null);
  const [isOpen, setIsOpen] = useState("open");

  // const fetchMarketStatus = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://api.polygon.io/v1/marketstatus/now?apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
  //     );
  //     const data = await response.json();
  //     setIsOpen(data?.market);
  //   } catch (error) {
  //     console.error("Error fetching symbol list: ", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchMarketStatus();
  // }, []);

  const fetchProfitDetails = async (ticketNumber) => {
    try {
      const response = await fetch(
        "https://makassab.com/trading_platform/backend/platform/getProfit.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ticketNumber }),
        }
      );

      const result = await response.json();
      if (result.status === "success") {
        return { lots: result.lots, leverage: result.leverage };
      } else {
        console.error("Error fetching profit details:", result);
        return { lots: 0, leverage: 0 }; // Fallback in case of failure
      }
    } catch (error) {
      console.error("Fetch error:", error);
      return { lots: 0, leverage: 0 }; // Fallback in case of error
    }
  };

  const fetchDataValue = async () => {
    const apiURL =
      selectedItem.currencyType === "Forex"
        ? `https://api.polygon.io/v1/conversion/USD/${selectedItem.fullSymbo.substring(
            3
          )}?amount=100&precision=2&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
        : selectedItem.currencyType === "Crypto"
        ? `https://api.binance.com/api/v3/ticker/bookTicker?symbol=${selectedItem.fullSymbo}USDT`
        : selectedItem.currencyType === "Metals"
        ? `https://api.polygon.io/v1/conversion/USD/${selectedItem.fullSymbo.substring(
            3
          )}?amount=100&precision=2&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
        : `https://api.polygon.io/v2/last/nbbo/${selectedItem.fullSymbo.substring(
            3
          )}?apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`;

    // const apiURL =
    //   selectedItem.fullSymbol.length > 5
    //     ? `https://api.polygon.io/v1/conversion/USD/${selectedItem.fullSymbol.substring(
    //         3
    //       )}?amount=100&precision=2&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
    //     : `https://api.binance.com/api/v3/ticker/bookTicker?symbol=${selectedItem.fullSymbol}USDT`;

    try {
      const response = await fetch(apiURL);
      const responseData = await response.json();

      const Bid =
        selectedItem.currencyType === "Forex"
          ? responseData.last.bid.toFixed(5)
          : selectedItem.currencyType === "Crypto"
          ? parseFloat(responseData.bidPrice).toFixed(5)
          : selectedItem.currencyType === "Metals"
          ? responseData.last.bid.toFixed(5)
          : responseData.results.p.toFixed(5);

      const Ask =
        selectedItem.currencyType === "Forex"
          ? responseData.last.ask.toFixed(5)
          : selectedItem.currencyType === "Crypto"
          ? parseFloat(responseData.askPrice).toFixed(5)
          : selectedItem.currencyType === "Metals"
          ? responseData.last.ask.toFixed(5)
          : responseData.results.P.toFixed(5);

      const { lots, leverage } = await fetchProfitDetails(selectedItem.ticket);

      const profit =
        selectedItem.type === "Buy"
          ? (Bid - selectedItem.orginalPrice) *
            lots *
            leverage *
            selectedItem.volume
          : (selectedItem.orginalPrice - Ask) *
            lots *
            leverage *
            selectedItem.volume;

      setSelectedItem((prevSelectedItem) => ({
        ...prevSelectedItem,
        ask:
          selectedItem.fullSymbol.length > 5
            ? responseData.last.ask.toFixed(5)
            : parseFloat(responseData.askPrice).toFixed(5),
        bid:
          selectedItem.fullSymbol.length > 5
            ? responseData.last.bid.toFixed(5)
            : parseFloat(responseData.bidPrice).toFixed(5),
        profit: isNaN(profit) ? "" : profit.toFixed(5),
      }));
      setSelectedRow(selectedItem);
    } catch (error) {
      console.error("Error fetching data: ", error);
      return { ask: null, bid: null };
    }
  };

  // Use the useInterval hook at the top level of the component
  const delay = isOpen !== "close" ? 2000 : null; // Check if isOpen is specifically "open"
  useInterval(() => {
    if (selectedItem) {
      fetchDataValue();
    }
  }, delay);

  useEffect(() => {
    if (isOpen === "close") {
      fetchDataValue(); // Fetch data once when component mounts or symbol list changes
    }
  }, [isOpen]);

  // const calculateProfit = (value, TotalBalance) => {
  //   if (!value) return "";

  //   const profit =
  //     value.type === "Buy"
  //       ? (value.bid - value.orginalPrice) * value.volume * TotalBalance
  //       : (value.orginalPrice - value.ask) * value.volume * TotalBalance;

  //   if (isNaN(profit)) return "";

  //   const formattedProfit = profit.toFixed(4);

  //   return formattedProfit;
  // };

  // useEffect(() => {
  //   if (selectedItem) {
  //     // setProfitValue(calculateProfit(selectedItem, TotalBalance));
  //   }
  // }, [selectedItem?.bid, selectedItem?.ask]);

  const handleDelete = (orderToDelete) => {
    let finalBidValue = orderToDelete?.bid;
    let finalAskValue = orderToDelete?.ask;

    const deletedRecord = {
      ticket: orderToDelete.ticket,
      lastPrice: orderToDelete.type === "Buy" ? finalBidValue : finalAskValue,
      profit: orderToDelete.profit,
    };

    setDeleteRecord(!deleteRecord);

    fetch(
      "https://makassab.com/trading_platform/backend/platform/updateTrades.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(deletedRecord),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setDeleteRecord(!deleteRecord);
          setVisible(false);
        }
      });
  };

  return (
    <Sidebar
      visible={visible}
      position="bottom"
      onHide={() => setVisible(false)}
      style={{ height: "45%" }}
      className="custom-sidebar"
    >
      <div className="flex flex-column mt-2">
        {selectedItem && (
          <>
            <div className="flex flex-row justify-content-between mb-2">
              <div className="flex text-sm text-color-secondary">
                {selectedItem.timeStamp}
              </div>
              <div className="flex flex-column text-sm text-color-secondary">
                <div className="flex">{selectedItem.ticket}</div>
                <div className="flex border-bottom-2 text-sm text-600"></div>
              </div>
            </div>
            <div className="flex flex-row justify-content-between mb-2">
              <div className="flex flex-row">
                <div className="flex mr-1 font-medium text-900">
                  {selectedItem.fullSymbol}
                </div>
                <div
                  className="flex mr-1 text-sm align-items-center"
                  style={{ color: selectedItem.profit > 0 ? "blue" : "red" }}
                >
                  {selectedItem.type.toLowerCase()} {selectedItem.volume}
                </div>
              </div>
              <div className="flex font-medium text-sm">
                {selectedItem.profit}
              </div>
            </div>
            <div className="flex flex-row text-sm mb-2 text-900 font-medium">
              <div className="flex mr-1">{selectedItem.orginalPrice}</div>
              <div className="flex mr-1 ml-1 mt-1 align-items-center">
                <i
                  className="fa-solid fa-arrow-right-long"
                  style={{
                    fontSize: "11px",
                  }}
                ></i>
              </div>
              <div className="flex">
                {selectedItem.type === "Buy"
                  ? selectedItem.bid
                  : selectedItem.ask}
              </div>
            </div>

            <div className="flex flex-row mb-2">
              <div className="flex flex-row w-5 font-medium">
                <div className="flex w-6 text-sm text-color-secondary">
                  S / L:
                </div>
                <div className="flex">{selectedItem.stopLoss}</div>
              </div>
              <div className="flex flex-row font-medium">
                <div className="flex text-sm text-color-secondary">Swap:</div>
                <div className="flex ml-3">{selectedItem.swap}</div>
              </div>
            </div>
            <div className="flex flex-row w-5 mb-2 font-medium">
              <div className="flex w-6 text-sm text-color-secondary">
                T / P:
              </div>
              <div className="flex">{selectedItem.takeProfit}</div>
            </div>
          </>
        )}
        <div className="flex border-bottom-2 text-300 mb-1"></div>
        <div className="flex align-items-center justify-content-center p-2">
          <Button
            label="Close Position"
            text
            className="text-red-600"
            style={{ boxShadow: "none" }}
            onClick={() => {
              if (isOpen !== "closed") {
                handleDelete(selectedItem);
              }
            }}
          />
        </div>
        <div className="flex align-items-center justify-content-center p-2">
          <Button
            label="New Order"
            text
            className="text-900"
            style={{ boxShadow: "none" }}
            onClick={() => {
              setComponentShow("NewOrderMobileDialog");
              setComponentShowFrom("tradingTable");
            }}
          />
        </div>
        <div className="flex align-items-center justify-content-center p-2">
          <Button
            label="Chart"
            text
            className="text-900"
            style={{ boxShadow: "none" }}
            onClick={() => {
              setVisible(false);
              setComponentShow("chart");
            }}
          />
        </div>
      </div>
    </Sidebar>
  );
}
