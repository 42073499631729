import React from "react";
import { Button } from "primereact/button";
import { TieredMenu } from "primereact/tieredmenu";
import "./tired_Menu.css";
import trendLineImage from "../../icons/trendLine.png";
import horizontalLine from "../../icons/horizontalLine.png";
import gannLineImage from "../../icons/gannLine.png";
import rectangleImage from "../../icons/rectangle.png";
import textImage from "../../icons/text.png";
import eyeImage from "../../icons/eye.png";
import LockImage from "../../icons/padlock-unlock.png";

export function TieredButton({ menu, menuType }) {
  let items = [];

  // Add the font stylesheet link to your React component
  const fontStylesheetLink = (
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
    />
  );

  // Define the CSS styles for the icon font
  const iconFontStyles = `
.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}
`;

  // Determine menu items based on the menuType
  switch (menuType) {
    case 1:
      items = [
        {
          label: "Trendline",
          icon: (
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "22px", marginRight: "8px" }}
            >
              line_end_circle
            </span>
          ),
        },
        {
          label: "Horizontal Line",
          icon: (
            <img
              src={horizontalLine}
              alt="Trendline"
              style={{ width: "16px", height: "16px", marginRight: "8px" }}
            />
          ),
        },
        {
          label: "Horizontal Line",
          icon: "pi pi-search",
        },
        {
          label: "Horizontal Ray",
          icon: "pi pi-search",
        },
        {
          label: "Vertical Line",
          icon: "pi pi-search",
        },
        {
          label: "Info Line",
          icon: "pi pi-search",
        },
        {
          label: "Arrowed Line",
          icon: "pi pi-search",
        },
        {
          label: "Ray",
          icon: "pi pi-search",
        },
        {
          label: "Extended Line",
          icon: "pi pi-search",
        },
        {
          label: "CROSS Line",
          icon: "pi pi-search",
        },
        {
          label: "TREN By Angle",
          icon: "pi pi-search",
        },
        {
          label: "Disjoint Channel",
          icon: "pi pi-search",
        },
        {
          label: "Flat top/Bottom",
          icon: "pi pi-search",
        },
      ];
      break;
    case 2:
      items = [
        {
          label: "Regression Channel",
          icon: "pi pi-file",
        },
        {
          label: "Equidstant Channel",
          icon: "pi pi-search",
        },
        {
          label: "StdDev Channel",
          icon: "pi pi-search",
        },
        {
          label: "Andrews Pitchfork",
          icon: "pi pi-search",
        },
      ];
      break;
    case 3:
      items = [
        {
          label: "Gann Line",
          icon: "pi pi-file",
        },
        {
          label: "Gann Fan",
          icon: "pi pi-search",
        },
        {
          label: "Gann Grid",
          icon: "pi pi-search",
        },
      ];
      break;
    case 4:
      items = [
        {
          label: "Rectangle",
          icon: "pi pi-file",
        },
        {
          label: "Circle",
          icon: "pi pi-search",
        },
        {
          label: "Tiangle",
          icon: "pi pi-search",
        },
        {
          label: "Ellipse",
          icon: "pi pi-search",
        },
      ];
      break;
  }

  return (
    <div
      className="card flex justify-content-center"
      style={{ position: "relative" }}
    >
      <TieredMenu
        model={items}
        popup
        ref={menu}
        appendTo={document.body}
        className="TiredMenustyle"
      />
    </div>
  );
}
