import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useData } from "../../../context/DataContext";

import "./homePage.css";

export function HomePage({
  totalBalance,
  totalCredit,
  totalDeposit,
  totalWithdrawals,
  HistoryData,
  firstName,
  lastName,
}) {
  const { isMobile } = useData();
  const navigate = useNavigate();
  const [isAdminUser, setIsAdminUser] = useState(null); // Initial value set to null

  useEffect(() => {
    const storedIsAdminUser = localStorage.getItem("isAdminUser");
    if (storedIsAdminUser !== null) {
      setIsAdminUser(JSON.parse(storedIsAdminUser));
    }
  }, []);

  return (
    <div
      className="flex font-bold border-round pr-3 pl-3 pb-3"
      style={{ overflowX: "auto", overflowY: "auto" }}
    >
      <div className="flex flex-column" style={{ width: "100%" }}>
        {!isAdminUser && (
          <div
            className={`flex flex-row align-items-center justify-content-between w-full h-4rem bg-primary-100 text-primary mb-4  ${
              isMobile ? "text-xs" : "textStyle"
            }`}
          >
            <div className="flex flex-row">
              <i
                className="pi pi-exclamation-circle mr-3 ml-3"
                style={{ fontSize: "1rem" }}
              ></i>
              You can deposit up to USD 2,000. Complete full verification to
              make deposits without limitations.
            </div>

            <div className="flex">
              <Button
                severity="secondary"
                text
                raised
                className={`flex align-items-center font-medium bg-cyan-300 h-3rem ${
                  isMobile ? "text-xs" : "text-sm"
                }`}
                onClick={() => {
                  navigate("/dashboard/deposit_options");
                }}
              >
                Deposit Now
              </Button>
            </div>
            <div className="flex w-2rem h-2rem"></div>
          </div>
        )}
        <div className="flex flex-column border-round">
          <div className="flex textStyle mb-2 textStyle">Account Summary</div>

          <div
            className={`flex surface-card shadow-2 border-round mt-2 ${
              isMobile ? "flex-column" : "flex-row"
            }`}
            style={{ width: "100%" }}
          >
            <div
              class="flex align-items-center justify-content-center font-bold border-round p-2"
              style={{ width: isMobile ? "95%" : "80%" }}
            >
              <div
                className="flex flex-column"
                style={{ width: isMobile ? "100%" : "80%" }}
              >
                <div className="account-summary-container-caption text-primary">
                  Total Balance
                </div>
                <div className="account-summary-container-amount">
                  {totalBalance ? totalBalance.toFixed(4) : "0.00"} USD
                </div>
              </div>
              <div className="flex align-items-center justify-content-center bg-green-100 border-circle w-2rem h-2rem">
                <i
                  className="fa-solid fa-money-bill-1 text-green-600"
                  style={{
                    fontSize: "16px",
                    // color: "var(--surface-800)",
                  }}
                ></i>
              </div>
            </div>

            <div
              className={`flex align-items-center justify-content-center border-300 ${
                isMobile ? "border-top-1" : "border-1 mt-4 h-3rem"
              }`}
            ></div>

            <div
              class="flex align-items-center justify-content-center font-bold border-round p-2"
              style={{ width: isMobile ? "95%" : "80%" }}
            >
              <div
                className="flex flex-column"
                style={{ width: isMobile ? "100%" : "80%" }}
              >
                <div className="account-summary-container-caption text-primary">
                  Total Credit
                </div>
                <div className="account-summary-container-amount">
                  {totalCredit ? totalCredit.toFixed(4) : "0.00"} USD
                </div>
              </div>
              <div className="flex align-items-center justify-content-center bg-green-100 border-circle w-2rem h-2rem">
                <i
                  className="fa-solid fa-money-bill-1 text-green-600"
                  style={{
                    fontSize: "16px",
                    // color: "var(--surface-800)",
                  }}
                ></i>
              </div>
            </div>

            <div
              className={`flex align-items-center justify-content-center border-300 ${
                isMobile ? "border-top-1" : "border-1 mt-4 h-3rem"
              }`}
            ></div>

            <div
              class="flex align-items-center justify-content-center font-bold border-round p-2"
              style={{ width: isMobile ? "95%" : "80%" }}
            >
              <div
                className="flex flex-column"
                style={{ width: isMobile ? "100%" : "80%" }}
              >
                <div className="account-summary-container-caption text-primary">
                  Total Deposits
                </div>
                <div className="account-summary-container-amount">
                  {totalDeposit ? totalDeposit.toFixed(4) : "0.00"} USD
                </div>
              </div>
              <div className="flex align-items-center justify-content-center bg-cyan-100 border-circle w-2rem h-2rem">
                <i
                  className="pi pi-sign-in text-cyan-600"
                  style={{
                    fontSize: "16px",
                    // color: "var(--surface-800)",
                  }}
                ></i>
              </div>
            </div>

            <div
              className={`flex align-items-center justify-content-center border-300 ${
                isMobile ? "border-top-1" : "border-1 mt-4 h-3rem"
              }`}
            ></div>

            <div
              class="flex align-items-center justify-content-center font-bold border-round p-2"
              style={{ width: isMobile ? "95%" : "80%" }}
            >
              <div
                className="flex flex-column"
                style={{ width: isMobile ? "100%" : "80%" }}
              >
                <div className="account-summary-container-caption text-primary">
                  Total Withdrawals
                </div>
                <div className="account-summary-container-amount">
                  {totalWithdrawals ? totalWithdrawals.toFixed(4) : "0.00"} USD
                </div>
              </div>
              <div className="flex align-items-center justify-content-center bg-red-100 border-circle w-2rem h-2rem">
                <i
                  className="pi pi-sign-out text-red-600"
                  style={{
                    fontSize: "16px",
                    // color: "var(--surface-800)",
                  }}
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-column border-round mt-5">
          <div className="flex textStyle mb-3">History Trade</div>
          <div>
            <DataTable
              value={HistoryData}
              size={"small"}
              emptyMessage=" "
              className="font-base text-sm text-900"
              tableStyle={{ minHeight: !HistoryData ? "17rem" : "" }}
              scrollable
              scrollHeight="17rem"
            >
              <Column
                field="ID"
                header="ID"
                style={{ fontSize: isMobile ? "10px" : "14px" }}
              ></Column>
              <Column
                field="symbol"
                header="Symbol"
                style={{ fontSize: isMobile ? "10px" : "14px" }}
              ></Column>
              <Column
                field="timeStamp"
                header="TimeStamp"
                style={{ fontSize: isMobile ? "10px" : "14px" }}
              ></Column>
              <Column
                // field="tradeType"
                header="Trade Type"
                body={(rowData) => (
                  <span
                    style={{
                      color: rowData.tradeType === "Buy" ? "#32e315" : "red",
                    }}
                  >
                    {rowData.tradeType}
                  </span>
                )}
                style={{ fontSize: isMobile ? "10px" : "14px" }}
              ></Column>
              <Column
                field="volume"
                header="Volume"
                style={{ fontSize: isMobile ? "10px" : "14px" }}
              />
              <Column
                field="tradePrice"
                header="Trade Price"
                style={{ fontSize: isMobile ? "10px" : "14px" }}
              ></Column>
              <Column
                field="S\/L"
                header="S\/L"
                style={{ fontSize: isMobile ? "10px" : "14px" }}
              />
              <Column
                field="T\/P"
                header="T\/P"
                style={{ fontSize: isMobile ? "10px" : "14px" }}
              />
              <Column
                field="lastPrice"
                header="Last Price"
                style={{ fontSize: isMobile ? "10px" : "14px" }}
              />
              <Column
                // field="profit"
                header="Profit"
                body={(rowData) => (
                  <span
                    style={{
                      color: rowData.profit > 0 ? "#32e315" : "red",
                    }}
                  >
                    {rowData.profit}
                  </span>
                )}
                style={{ fontSize: isMobile ? "10px" : "14px" }}
              />
            </DataTable>
          </div>
        </div>
        <div className="flex flex-column border-round mt-5">
          <div className={`flex ${isMobile ? "flex-column" : "flex-row"}`}>
            <div
              className="surface-card shadow-2 border-round mt-2 mr-2"
              style={{ width: "100%" }}
            >
              <div className="flex justify-content-between">
                <div className="flex align-items-center justify-content-center">
                  <div
                    className={`flex align-items-center justify-content-center m-2  text-primary  ${
                      isMobile ? "text-xs" : "textStyle"
                    } `}
                  >
                    Account Name :
                  </div>
                  <div
                    className={`flex align-items-center justify-content-center m-2 ${
                      isMobile ? "account-value" : "font-normal"
                    }`}
                  >
                    {firstName} {lastName}
                  </div>
                </div>

                <div className="flex align-items-center justify-content-center">
                  <div
                    className={`flex align-items-center justify-content-center text-primary ${
                      isMobile ? "text-sm mr-2" : "textStyle mr-3"
                    }`}
                  >
                    Balance:
                  </div>
                  <div
                    className={`flex align-items-center justify-content-center ${
                      isMobile ? "account-value" : "font-normal"
                    }`}
                  >
                    {totalBalance ? totalBalance.toFixed(4) : "0.00"} USD
                  </div>
                </div>
                <div className="flex align-items-center justify-content-center "></div>
              </div>
            </div>

            {!isAdminUser && (
              <div className="flex flex-row mt-2">
                <Button
                  severity="secondary"
                  text
                  raised
                  className="mb-2 mr-2 font-semibold text-xs flex align-items-center bg-cyan-200"
                  style={{
                    position: "relative",
                    height: "100%",
                  }}
                  onClick={() => {
                    navigate("/dashboard/deposit_options");
                  }}
                >
                  <i
                    className={classNames("pi pi-sign-in")}
                    style={{
                      fontSize: "12px",
                      position: "absolute",
                      left: "7px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  ></i>
                  <span style={{ marginLeft: "10px" }}>Deposit</span>
                </Button>
                <Button
                  severity="secondary"
                  text
                  raised
                  className="mb-2 font-semibold text-xs flex align-items-center bg-red-100"
                  style={{
                    position: "relative",
                    height: "100%",
                  }}
                  onClick={() => navigate("/dashboard/withdraw_options")}
                >
                  <i
                    className={classNames("pi pi-sign-out")}
                    style={{
                      fontSize: "12px",
                      position: "absolute",
                      left: "7px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  ></i>
                  <span style={{ marginLeft: "10px" }}>Withdraw</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
