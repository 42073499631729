import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useData } from "../../context/DataContext";

const useInterval = (callback, delay) => {
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);

  return intervalRef;
};

export function NewOrderMobileDialog({
  selectedItemSymbol,
  setVisiblePriceTable,
  setVisibleDeleteSidebar,
  FreeMarginMobilePublic,
  FreeMarginLevelPublic,
  symbolType,
  setSymbolType,
}) {
  const [selectedWay, setSelectedWay] = useState(null);
  const [volume, setVolume] = useState(0.01);
  const [stopLoss, setStopLoss] = useState(0);
  const [takeProfit, setTakeProfit] = useState(0);
  const [comment, setComment] = useState("");
  const [bidColor, setBidColor] = useState("bg-blue-100");
  const [askColor, setAskColor] = useState("bg-blue-100");
  const {
    selectedRow,
    setAddRecord,
    addRecord,
    serverURL,
    setComponentShow,
    componentShowFrom,
    componentShow,
  } = useData();
  const [isOpenpMarket, setIsOpenMarket] = useState(true);
  // const [isOpen, setIsOpen] = useState(null);
  const [isOpen, setIsOpen] = useState("open");
  const [recordStatus, setRecordStatus] = useState(null);
  const [bidValue, setBidValue] = useState(selectedRow.bid);
  const [askValue, setAskValue] = useState(selectedRow.ask);
  const [firstStopLossClick, setFirstStopLossClick] = useState(true);
  const [firstTackProfitClick, setFirstTackProfitClick] = useState(true);
  const [disableSellButton, setDisableSellButton] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [symbolTypeDialog, setSymbolTypeDialog] = useState();

  useEffect(() => {
    setSymbolTypeDialog(symbolType.name);
  }, []);

  // const fetchMarketStatus = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://api.polygon.io/v1/marketstatus/now?apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
  //     );
  //     const data = await response.json();
  //     setIsOpen(data?.market);
  //   } catch (error) {
  //     console.error("Error fetching symbol list: ", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchMarketStatus();
  // }, [isOpen]);

  const fetchDataValue = async (symbol, currencyType) => {
    const apiURL =
      currencyType === "Forex"
        ? `https://api.polygon.io/v1/conversion/USD/${symbol.substring(
            3
          )}?amount=100&precision=2&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
        : currencyType === "Crypto"
        ? `https://api.binance.com/api/v3/ticker/bookTicker?symbol=${symbol}USDT`
        : currencyType === "Metals"
        ? `https://api.polygon.io/v1/conversion/USD/${symbol}?amount=100&precision=2&apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`
        : `https://api.polygon.io/v2/last/nbbo/${symbol}?apiKey=dRmrWUpRhenNWkJyg7hMLmBJLX0Kfq8s`;

    try {
      const response = await fetch(apiURL);
      const responseData = await response.json();

      setAskValue(
        currencyType === "Forex"
          ? responseData.last.ask.toFixed(5)
          : currencyType === "Crypto"
          ? parseFloat(responseData.askPrice).toFixed(5)
          : currencyType === "Metals"
          ? responseData.last.ask.toFixed(5)
          : responseData.results.P.toFixed(5)
      );

      setBidValue(
        currencyType === "Forex"
          ? responseData.last.bid.toFixed(5)
          : currencyType === "Crypto"
          ? parseFloat(responseData.bidPrice).toFixed(5)
          : currencyType === "Metals"
          ? responseData.last.bid.toFixed(5)
          : responseData.results.p.toFixed(5)
      );
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useInterval(
    () => {
      const symbolToFetch =
        selectedRow.fullSymbol || symbolTypeDialog.name === "Forex"
          ? `USD${selectedRow.Symbol}`
          : selectedRow.Symbol;
      fetchDataValue(symbolToFetch, selectedRow.currencyType);
    },
    isOpen !== "close" ? 1000 : null
  );

  const exchangeWay = [
    { name: "Instant Execution", code: "IE" },
    { name: "Buy Limit", code: "BL" },
    { name: "Sell Limit", code: "SL" },
    { name: "Buy Stop", code: "BS" },
    { name: "Sell Stop", code: "SS" },
    { name: "Buy Stop Limit", code: "BSL" },
    { name: "Sell Stop Limit", code: "SSL" },
  ];

  const handleNum = (type, status) => {
    const bid = selectedRow.bid;

    if (status === "decrease") {
      if (type === "volume" && volume - 0.01 > 0) {
        setVolume(volume - 0.01);
      } else if (type === "stopLoss" && stopLoss >= 0) {
        setDisableSellButton(true);
        if (firstStopLossClick) {
          let value = parseFloat(bid) + 0.00003;
          setStopLoss(value);
          setFirstStopLossClick(false);
        } else {
          if (stopLoss > 0) {
            setStopLoss(stopLoss - 0.00001);
          }
        }
      } else if (type === "takeProfit" && takeProfit >= 0) {
        setDisableSellButton(true);

        if (firstTackProfitClick) {
          let value = parseFloat(bid) + 0.003;
          setTakeProfit(value);
          setFirstTackProfitClick(false);
        } else {
          if (takeProfit > 0) {
            setTakeProfit(takeProfit - 0.00001);
          }
        }
      }
    } else {
      if (type === "volume") {
        setVolume(volume + 0.01);
      } else if (type === "stopLoss" && stopLoss >= 0) {
        setDisableSellButton(true);
        if (firstStopLossClick) {
          let value = parseFloat(bid) + 0.00003;
          setStopLoss(value);
          setFirstStopLossClick(false);
        } else {
          setStopLoss(stopLoss + 0.00001);
        }
      } else if (type === "takeProfit" && takeProfit >= 0) {
        setDisableSellButton(true);

        if (firstTackProfitClick) {
          let value = parseFloat(bid) + 0.003;
          setTakeProfit(value);
          setFirstTackProfitClick(false);
        } else {
          setTakeProfit(takeProfit + 0.00001);
        }
      }
    }
  };

  const handleOrderSell = (action) => {
    let bid = selectedRow?.bid;

    // Construct the request payload
    const payloadSell = {
      fullSymbol:
        componentShowFrom === "priceTable"
          ? symbolTypeDialog.name === "Forex"
            ? "USD" + selectedRow?.Symbol
            : symbolTypeDialog.name === "Metals"
            ? "USD" + selectedRow?.Symbol
            : selectedRow?.Symbol
          : selectedRow.fullSymbol,

      currenciesTypeName: selectedRow.CurrencyType,
      tradestypes: action.toLowerCase(), // Convert to lowercase as per your API requirement
      volume: volume,
      orginalPrice: bid,
      stopLoss: stopLoss,
      takeProfit: takeProfit,
      freeMargin: FreeMarginMobilePublic,
      marginLevel: FreeMarginLevelPublic,
    };

    setRecordStatus(payloadSell);

    if (isOpen !== "closed") {
      // Make the API request
      fetch(`${serverURL}/platform/addTrades.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payloadSell),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setAddRecord(!addRecord);
            setSelectedWay(null);
            setVolume(0.01);
            setStopLoss(0);
            setTakeProfit(0);
            setComment("");
            setIsOpenMarket(true);
            setFirstStopLossClick(true);
            setFirstTackProfitClick(true);
            setDisableSellButton(false);
            if (componentShowFrom === "priceTable") {
              setVisiblePriceTable(false);
            } else {
              setVisibleDeleteSidebar(false);
            }
            setComponentShow(componentShowFrom);
          } else if (data.status === "Failure") {
            setMessage(data.message);
            setShowMessage(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setIsOpenMarket(false);
    }
  };

  const handleOrderBuy = (action) => {
    let ask = selectedRow?.ask;

    const payload = {
      fullSymbol:
        componentShowFrom === "priceTable"
          ? symbolTypeDialog.name === "Forex"
            ? "USD" + selectedRow?.Symbol
            : symbolTypeDialog.name === "Metals"
            ? "USD" + selectedRow?.Symbol
            : selectedRow?.Symbol
          : selectedRow.fullSymbol,

      currenciesTypeName: selectedRow.CurrencyType,
      tradestypes: action.toLowerCase(), // Convert to lowercase as per your API requirement
      volume: volume,
      orginalPrice: ask,
      stopLoss: stopLoss,
      takeProfit: takeProfit,
      freeMargin: FreeMarginMobilePublic,
      marginLevel: FreeMarginLevelPublic,
    };

    setRecordStatus(payload);

    if (isOpen !== "closed") {
      // Make the API request
      fetch(`${serverURL}/platform/addTrades.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setAddRecord(!addRecord);
            setSelectedWay(null);
            setVolume(0.01);
            setStopLoss(0);
            setTakeProfit(0);
            setComment("");
            setIsOpenMarket(true);
            setFirstStopLossClick(true);
            setFirstTackProfitClick(true);
            setDisableSellButton(false);
            if (componentShowFrom === "priceTable") {
              setVisiblePriceTable(false);
            } else {
              setVisibleDeleteSidebar(false);
            }
            setComponentShow(componentShowFrom);
          } else if (data.status === "Failure") {
            setMessage(data.message);
            setShowMessage(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setIsOpenMarket(false);
    }
  };

  const prevBidRef = useRef(null);
  const prevAskRef = useRef(null);

  useEffect(() => {
    if (selectedRow?.bid !== prevBidRef.current) {
      setBidColor(
        selectedRow?.bid > prevBidRef.current ? "text-blue-400" : "text-red-400"
      );
      prevBidRef.current = selectedRow?.bid;
    }

    if (selectedRow?.ask !== prevAskRef.current) {
      setAskColor(
        selectedRow?.ask > prevAskRef.current ? "text-blue-400" : "text-red-400"
      );
      prevAskRef.current = selectedRow?.ask;
    }
  }, [selectedRow?.bid, selectedRow?.ask]);

  return (
    <div>
      {isOpenpMarket ? (
        !showMessage ? (
          <div className="flex flex-column p-2">
            <div class="flex justify-content-between">
              <div class="flex align-items-center justify-content-center w-4rem  m-2"></div>
              {selectedItemSymbol && (
                <div class="flex align-items-center justify-content-center font-bold border-round m-2">
                  USD{selectedItemSymbol.Symbol}
                </div>
              )}
              <div class="flex align-items-center justify-content-center  m-2">
                <Button
                  icon="pi pi-times"
                  severity="secondary"
                  style={{
                    fontSize: "10px",
                    color: "var(--surface-800)",
                    boxShadow: "none",
                  }}
                  text
                  onClick={() => {
                    if (componentShowFrom === "priceTable") {
                      setVisiblePriceTable(false);
                    } else {
                      setVisibleDeleteSidebar(false);
                    }
                    setComponentShow(componentShowFrom);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-column mt-3">
              <Dropdown
                value={selectedWay}
                onChange={(e) => setSelectedWay(e.value)}
                options={exchangeWay}
                optionLabel="name"
                placeholder="Exchange Way"
                className="w-full"
              />
              <div className="flex justify-content-between text-xs mt-3">
                <div className="text-900">Volume</div>
                <div className="text-900">1 000.00 EUR</div>
              </div>

              <div className="p-inputgroup border-1 border-300 mt-1 text-xs w-full h-2rem">
                <Button
                  icon="pi pi-minus"
                  className="border-none bg-white small-icon"
                  style={{ color: "var(--surface-800)" }}
                  onClick={() => handleNum("volume", "decrease")}
                />
                <InputNumber
                  value={volume}
                  onValueChange={(e) => setVolume(e.value)}
                  className="flex align-items-center justify-content-center inputBox"
                />
                <Button
                  icon="pi pi-plus"
                  className="border-none bg-white small-icon"
                  style={{ color: "var(--surface-800)" }}
                  onClick={() => handleNum("volume", "increase")}
                />
              </div>
            </div>

            <div class="flex justify-content-between mt-3">
              <div className="flex flex-column text-xs">
                <div className="flex text-900">Stop Loss</div>

                <div className="p-inputgroup border-1 border-300 mt-1 h-2rem">
                  <Button
                    icon="pi pi-minus"
                    className="border-none bg-white small-icon"
                    style={{ color: "var(--surface-800)" }}
                    onClick={() => handleNum("stopLoss", "decrease")}
                  />
                  <InputNumber
                    value={stopLoss}
                    onValueChange={(e) => setStopLoss(e.value)}
                    minFractionDigits={5}
                    maxFractionDigits={5}
                    className="flex align-items-center justify-content-center inputBox"
                  />
                  <Button
                    icon="pi pi-plus"
                    className="border-none bg-white small-icon"
                    style={{ color: "var(--surface-800)" }}
                    onClick={() => handleNum("stopLoss", "increase")}
                  />
                </div>
              </div>
              <div class="flex align-items-center justify-content-center"></div>
              <div className="flex flex-column text-xs">
                <div className="flex text-900">Take Profit</div>

                <div className="p-inputgroup border-1 border-300 mt-1 h-2rem">
                  <Button
                    icon="pi pi-minus"
                    className="border-none bg-white small-icon"
                    style={{ color: "var(--surface-800)" }}
                    onClick={() => handleNum("takeProfit", "decrease")}
                  />
                  <InputNumber
                    value={takeProfit}
                    onValueChange={(e) => setTakeProfit(e.value)}
                    minFractionDigits={5}
                    maxFractionDigits={5}
                    className="flex align-items-center justify-content-center inputBox"
                  />
                  <Button
                    icon="pi pi-plus"
                    className="border-none bg-white small-icon"
                    style={{ color: "var(--surface-800)" }}
                    onClick={() => handleNum("takeProfit", "increase")}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-column mt-3 text-xs">
              <div className="flex mb-1 text-900">Comment</div>
              <InputText
                value={comment}
                className="h-2rem"
                onChange={(e) => setComment(e.target.value)}
              />
            </div>

            <div className="flex flex-column align-items-center justify-content-center mt-2">
              <div className="flex flex-row bg-blue-50 mb-1 w-full">
                <div
                  className={`flex align-items-center justify-content-center w-6 h-2rem ${bidColor}`}
                  style={{ marginRight: "1px" }}
                >
                  {/* {selectedRow?.bid} */}
                  {bidValue}
                </div>

                <div className="flex align-items-center justify-content-center text-300">
                  |
                </div>

                <div
                  className={`flex align-items-center justify-content-center w-6 h-2rem ${askColor}`}
                  style={{ marginRight: "1px" }}
                >
                  {/* {selectedRow?.ask} */}
                  {askValue}
                </div>
              </div>

              <div className="flex flex-row w-full">
                <Button
                  className="border-none font-normal w-6"
                  style={{
                    backgroundColor: !disableSellButton
                      ? "red"
                      : "var(--red-400)",
                    marginRight: "0.5px",
                  }}
                  label="Sell"
                  onClick={() => handleOrderSell("Sell")}
                  disabled={disableSellButton}
                />
                <Button
                  className="border-none font-normal w-6"
                  style={{ backgroundColor: "blue" }}
                  label="Buy"
                  onClick={() => handleOrderBuy("Buy")}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="flex flex-column w-full"
            style={{ position: "relative" }}
          >
            <div class="flex justify-content-between">
              <div class="flex align-items-center justify-content-center w-4rem  m-2"></div>
              <div class="flex align-items-center justify-content-center  m-2">
                <Button
                  icon="pi pi-times"
                  severity="secondary"
                  style={{
                    fontSize: "10px",
                    color: "var(--surface-800)",
                    boxShadow: "none",
                  }}
                  text
                  onClick={() => {
                    if (componentShowFrom === "priceTable") {
                      setVisiblePriceTable(false);
                    } else {
                      setVisibleDeleteSidebar(false);
                    }
                    setComponentShow(componentShowFrom);
                  }}
                />
              </div>
            </div>
            <div
              className="flex flex-column align-items-center mt-6"
              style={{ height: "calc(100vh - 155px)" }}
            >
              <div className="flex align-items-center justify-content-center font-medium text-red-500	mt-2">
                {message}
              </div>
            </div>
            <div
              className="flex align-content-end w-full"
              style={{ position: "absolute", bottom: "0" }}
            >
              <Button
                label="OK"
                severity="warning"
                className="w-full"
                onClick={() => {
                  if (componentShowFrom === "priceTable") {
                    setVisiblePriceTable(false);
                  } else {
                    setVisibleDeleteSidebar(false);
                  }
                  setComponentShow(componentShowFrom);
                }}
              />
            </div>
          </div>
        )
      ) : (
        <div
          className="flex flex-column w-full"
          style={{ position: "relative" }}
        >
          <div class="flex justify-content-between">
            <div class="flex align-items-center justify-content-center w-4rem  m-2"></div>
            {recordStatus && (
              <div class="flex align-items-center justify-content-center font-bold border-round m-2">
                {recordStatus.fullSymbol}
              </div>
            )}
            <div class="flex align-items-center justify-content-center  m-2">
              <Button
                icon="pi pi-times"
                severity="secondary"
                style={{
                  fontSize: "10px",
                  color: "var(--surface-800)",
                  boxShadow: "none",
                }}
                text
                onClick={() => {
                  if (componentShowFrom === "priceTable") {
                    setVisiblePriceTable(false);
                  } else {
                    setVisibleDeleteSidebar(false);
                  }
                  setComponentShow(componentShowFrom);
                }}
              />
            </div>
          </div>
          <div
            className="flex flex-column align-items-center mt-6"
            style={{ height: "calc(100vh - 155px)" }}
          >
            <div className="flex align-items-center justify-content-center">
              {recordStatus?.tradestypes} {recordStatus?.volume}{" "}
              {recordStatus?.fullSymbol} at {recordStatus?.orginalPrice}
            </div>
            <div className="flex align-items-center justify-content-center font-medium text-red-500	mt-2">
              Market Closed
            </div>
          </div>
          <div
            className="flex align-content-end w-full"
            style={{ position: "absolute", bottom: "0" }}
          >
            <Button
              label="OK"
              severity="warning"
              className="w-full"
              onClick={() => {
                if (componentShowFrom === "priceTable") {
                  setVisiblePriceTable(false);
                } else {
                  setVisibleDeleteSidebar(false);
                }
                setComponentShow(componentShowFrom);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
