import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useData } from "../../../context/DataContext";
import { Toast } from "primereact/toast";
import axios from "axios";
import "./DepositPage.css";

export function DepositPage() {
  const { serverURL, isMobile } = useData();
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [userAccount, setUserAccount] = useState();
  const [depositAmount, setDepositAmount] = useState(0);
  const [value1, setValue1] = useState(0);
  const toast = useRef(null);
  const [accounts, setAccounts] = useState([]);
  // const moneySymbol = [
  //   { name: "USD", code: "NY" },
  //   { name: "EUR", code: "EU" },
  //   { name: "GBP", code: "UK" },
  //   { name: "JPY", code: "JP" },
  //   { name: "AUD", code: "AU" },
  //   { name: "CAD", code: "CA" },
  //   { name: "CHF", code: "CH" },
  //   { name: "CNY", code: "CN" },
  //   { name: "HKD", code: "HK" },
  //   { name: "NZD", code: "NZ" },
  //   { name: "SEK", code: "SE" },
  //   { name: "KRW", code: "KR" },
  //   { name: "SGD", code: "SG" },
  //   { name: "NOK", code: "NO" },
  //   { name: "MXN", code: "MX" },
  //   { name: "INR", code: "IN" },
  //   { name: "BRL", code: "BR" },
  //   { name: "RUB", code: "RU" },
  //   { name: "ZAR", code: "ZA" },
  //   { name: "TRY", code: "TR" },
  // ];
  // const [selectedMoneySymbol, setSelectedMoneySymbol] = useState(
  //   moneySymbol[0]
  // );

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const response = await axios.get(
          "https://makassab.com/trading_platform/backend/platform/getPaymentGetwaysActive.php"
        );
        if (response.data.status === "success") {
          // Extract the 'name' and set it in the accounts array
          const accountData = response.data.data.map((account) => ({
            name: account.name,
            number: account.number,
            moreInfo: account.moreInfo,
            id: account.id,
          }));
          setAccounts(accountData);
        }
      } catch (error) {
        console.error("Error fetching profile details:", error);
      }
    };

    fetchAccount();
  }, []);

  const handleContinue = async () => {
    try {
      const response = await axios.post(
        "https://makassab.com/trading_platform/backend/platform/addDeposite.php",
        {
          depositAmount: depositAmount,
          paymentAccount: userAccount,
          payment_getways_id: selectedAccount.id,
        }
      );
      if (response.data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "success",
          detail: "Deposit request send successfully, wait Admin Aprovall",
        });
        setDepositAmount(0);
        setUserAccount("");
        setSelectedAccount(null);
      }
    } catch (error) {
      console.log("API error");
    }
  };

  return (
    <div className={`flex flex-column w-full ${isMobile ? "p-6" : "p-8"}`}>
      <Toast ref={toast} />

      <div className="flex flex-column border-round">
        <div className="flex justify-content-start flex-wrap">
          <div className="flex font-medium">
            Fund Your Account Easily and Securely
          </div>
        </div>
        <div className="flex flex-column align-items-center surface-card shadow-2 p-3 border-round mt-2">
          <div className={`flex flex-column ${isMobile ? "w-full" : "w-6"}`}>
            <div className="flex flex-column w-full">
              <div className="flex font-medium text-sm mb-2">
                Select an Account
              </div>
              <div className="flex">
                <Dropdown
                  value={selectedAccount}
                  onChange={(e) => setSelectedAccount(e.value)}
                  options={accounts} // Use the accounts array
                  optionLabel={(option) =>
                    `${option.name} -- ${option.number}${
                      option.moreInfo ? ` -- ${option.moreInfo}` : ""
                    }`
                  }
                  placeholder="Select Account"
                  className="w-full"
                />
              </div>
            </div>
            <div className="flex flex-column w-full mt-4">
              <div className="flex font-medium text-sm mb-2">
                Enter Your Account
              </div>
              <div className="flex flex-column">
                <InputText
                  value={userAccount}
                  onChange={(e) => setUserAccount(e.target.value)}
                  className="w-full"
                />
                <small id="username-help" className="flex text-sm">
                  Enter the last four digits of your bank account number.
                </small>
              </div>
            </div>
            <div className="flex flex-column mt-4 w-full">
              <div className="flex font-medium text-sm mb-2">
                Deposit Amount
              </div>
              <div className="flex flex-row w-full">
                {/* <Dropdown
                  value={selectedMoneySymbol}
                  onChange={(e) => setSelectedMoneySymbol(e.value)}
                  options={moneySymbol}
                  optionLabel="name"
                  className="w-7rem"
                /> */}
                {/* <InputText className="w-7rem" disabled placeholder="USD" /> */}
                <div className=" flex w-7rem align-items-center border-round border-2 border-300 ">
                  <div className="flex text-600	ml-2">USD</div>
                </div>
                <InputNumber
                  inputId="minmaxfraction"
                  value={depositAmount}
                  onValueChange={(e) => setDepositAmount(e.value)}
                  minFractionDigits={2}
                  className="w-full custome"
                />
              </div>
            </div>
            <Button
              label="Continue"
              className="w-full mt-5 mb-6"
              style={{ backgroundColor: "#00afab" }}
              onClick={handleContinue}
            />
          </div>
          <div className="w-full border-1 border-300"></div>
          <div className="flex font-meduim text-xs mt-3 text-color-secondary">
            Please note that if you are using a new payment method that was not
            used before, your deposit might not be applied immediately into your
            account and might take up to 24 hours to be reviewed and processed.
            On some occasions, we might request proof that the card or payment
            account is under your name.
          </div>
        </div>
      </div>
    </div>
  );
}
