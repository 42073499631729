import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { useData } from "../../context/DataContext";
import { TieredButton } from "../SidebarMenuContent/Tiered_Menu";
import trendLineImage from "../../icons/trendLine.png";
import gannLineImage from "../../icons/gannLine.png";
import rectangleImage from "../../icons/rectangle.png";
import textImage from "../../icons/text.png";
import eyeImage from "../../icons/eye.png";
import LockImage from "../../icons/padlock-unlock.png";

export function MobileSidebarMenu() {
  const [severityGroup1, setSeverityGroup1] = useState({
    AlignLeft: "secondary",
  });
  const [severityGroup2, setSeverityGroup2] = useState({
    menu1Button: "secondary",
    menu2Button: "secondary",
    menu3Button: "secondary",
    menu4Button: "secondary",
  });

  const { crosshairShow, setCrosshairShow } = useData();
  const menu1 = useRef(null);
  const menu2 = useRef(null);
  const menu3 = useRef(null);
  const menu4 = useRef(null);

  const crosshair = () => {
    setCrosshairShow(crosshairShow == true ? false : true);
  };

  const handleButtonClickGroup1 = (buttonName) => {
    setSeverityGroup1((prevSeverity) => ({
      ...Object.fromEntries(
        Object.keys(prevSeverity).map((key) => [
          key,
          key === buttonName ? "primary" : "secondary",
        ])
      ),
    }));
  };

  const handleButtonClickGroup2 = (buttonName) => {
    setSeverityGroup2((prevSeverity) => ({
      ...Object.fromEntries(
        Object.keys(prevSeverity).map((key) => [
          key,
          key === buttonName ? "primary" : "secondary",
        ])
      ),
    }));
  };

  const selectedButton = (num) => {
    switch (num) {
      case 1:
        handleButtonClickGroup1("AlignLeft");
        break;
      case 4:
        handleButtonClickGroup2("menu1Button");
        break;
      case 5:
        handleButtonClickGroup2("menu2Button");
        break;
      case 6:
        handleButtonClickGroup2("menu3Button");
        break;
      case 7:
        handleButtonClickGroup2("menu4Button");
        break;
    }
  };

  const selectedMenu = (num, e) => {
    switch (num) {
      case 1:
        menu1.current.toggle(e);
        selectedButton(4);
        break;
      case 2:
        menu2.current.toggle(e);
        selectedButton(5);
        break;
      case 3:
        menu3.current.toggle(e);
        selectedButton(6);
        break;
      case 4:
        menu4.current.toggle(e);
        selectedButton(7);
        break;
    }
  };

  return (
    <div className="flex overflow-hidden flex-column border-right-2 surface-border">
      <div className="flex-none flex flex-column align-items-center justify-content-center ">
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <i
                className="fa-solid fa-up-down-left-right"
                style={{ fontSize: "16px", color: "var(--surface-800)" }}
              ></i>
            }
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
            onClick={crosshair}
          />
        </div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <img
                src={trendLineImage}
                alt="Icon"
                style={{
                  width: "16px",
                  height: "16px",
                  color: "var(--surface-800)",
                }}
              />
            }
            severity={severityGroup2.menu1Button}
            style={{ boxShadow: "none" }}
            text
            onClick={(e) => selectedMenu(1, e)}
          />
          <TieredButton menu={menu1} menuType={1} />
        </div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon="pi pi-align-left"
            severity={severityGroup2.menu2Button}
            style={{ boxShadow: "none" }}
            text
            onClick={(e) => selectedMenu(2, e)}
          />
          <TieredButton menu={menu2} menuType={2} />
        </div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <img
                src={gannLineImage}
                alt="Icon"
                style={{
                  width: "16px",
                  height: "16px",
                  color: "var(--surface-800)",
                }}
              />
            }
            severity={severityGroup2.menu3Button}
            style={{ boxShadow: "none" }}
            text
            onClick={(e) => selectedMenu(3, e)}
          />
          <TieredButton menu={menu3} menuType={3} />
        </div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <img
                src={rectangleImage}
                alt="Icon"
                style={{
                  width: "16px",
                  height: "16px",
                  color: "var(--surface-800)",
                }}
              />
            }
            severity={severityGroup2.menu4Button}
            style={{ boxShadow: "none" }}
            text
            onClick={(e) => selectedMenu(4, e)}
          />
        </div>
        <TieredButton menu={menu4} menuType={4} />

        <div className="flex align-items-center justify-content-center">
          <Button
            icon="pi pi-align-left"
            severity="secondary"
            text
            style={{ boxShadow: "none" }}
          />
        </div>

        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <img
                src={textImage}
                alt="Icon"
                style={{
                  width: "16px",
                  height: "16px",
                  color: "var(--surface-800)",
                }}
              />
            }
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
          />
        </div>

        <div
          className="surface-300"
          style={{ height: "1.5px", width: "30px" }}
        ></div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <img
                src={eyeImage}
                alt="Icon"
                style={{
                  width: "16px",
                  height: "16px",
                  color: "var(--surface-800)",
                }}
              />
            }
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
          />
        </div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <img
                src={LockImage}
                alt="Icon"
                style={{
                  width: "16px",
                  height: "16px",
                  color: "var(--surface-800)",
                }}
              />
            }
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
          />
        </div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon="pi pi-align-left"
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
          />
        </div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon="pi pi-align-left"
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
          />
        </div>
      </div>
    </div>
  );
}
