import React, { useState } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useData } from "../../context/DataContext";

export function TopHeader({
  setPriceDialogVisible,
  setNewOrderDialogVisible,
  priceDialogVisible,
}) {
  const {
    setShowChart,
    setAreaChartShow,
    setTraidChartShow,
    setTimeSpan,
    setMultiplier,
  } = useData();

  const navigate = useNavigate();
  const [selectedPeriod, setSelectedPeriod] = useState("H1");

  const handleShowChart = () => {
    setShowChart(true);
  };

  const handleHideChart = () => {
    setShowChart(false);
  };

  const handleShowAreaChart = () => {
    setAreaChartShow(true);
    setTraidChartShow(false);
  };

  const handleShowTraidChart = () => {
    setTraidChartShow(true);
    setAreaChartShow(false);
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const timePeriod = (period) => {
    setSelectedPeriod(period);
    switch (period) {
      case "D1":
        setTimeSpan("day");
        setMultiplier(1);
        break;
      case "W1":
        setTimeSpan("week");
        setMultiplier(1);
        break;
      case "M1":
        setTimeSpan("minute");
        setMultiplier(1);
        break;
      case "M5":
        setTimeSpan("minute");
        setMultiplier(5);
        break;
      case "M15":
        setTimeSpan("minute");
        setMultiplier(15);
        break;
      case "M30":
        setTimeSpan("minute");
        setMultiplier(30);
        break;
      case "H1":
        setTimeSpan("hour");
        setMultiplier(1);
        break;
      case "H4":
        setTimeSpan("hour");
        setMultiplier(4);
        break;
      case "MN":
        setTimeSpan("month");
        setMultiplier(1);
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="flex flex-row border-bottom-2 surface-border justify-content-between"
      // style={{ overflowX: "auto" }}
    >
      <div className="flex flex-row">
        <div className="flex align-items-center justify-content-center">
          <Button
            icon="pi pi-sliders-v"
            severity="secondary"
            style={{ color: "var(--surface-800)", boxShadow: "none" }}
            text
          />
        </div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <i
                className="fa-solid fa-chart-simple"
                style={{ fontSize: "16px", color: "var(--surface-800)" }}
              ></i>
            }
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
            onClick={handleShowTraidChart}
          />
        </div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <i
                className="fa-solid fa-chart-line"
                style={{ fontSize: "16px", color: "var(--surface-800)" }}
              ></i>
            }
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
            onClick={handleShowAreaChart}
          />
        </div>
        <div
          className="border-right-2 border-300"
          style={{ margin: "7px" }}
        ></div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <i
                className="fa-solid fa-chart-column"
                style={{ fontSize: "16px", color: "var(--surface-800)" }}
              ></i>
            }
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
            onClick={handleShowChart}
          />
        </div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <i
                className="fa-solid fa-chart-column"
                style={{ fontSize: "16px", color: "var(--surface-600)" }}
              ></i>
            }
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
            onClick={handleHideChart}
          />
        </div>
        <div
          className="border-right-2 border-300"
          style={{ margin: "7px" }}
        ></div>

        {["M1", "M5", "M15", "M30", "H1", "H4", "D1", "W1", "MN"].map(
          (period) => (
            <Button
              key={period}
              className={`text-600 font-medium text-sm ${
                selectedPeriod === period ? "text-blue-500" : ""
              }`}
              label={period}
              severity="secondary"
              style={{ boxShadow: "none" }}
              text
              onClick={() => timePeriod(period)}
            />
          )
        )}

        <div
          className="border-right-2 border-300"
          style={{ margin: "7px" }}
        ></div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <i
                className="fa-solid fa-money-bill"
                style={{ fontSize: "16px", color: "var(--surface-800)" }}
              ></i>
            }
            severity="secondary"
            style={{ boxShadow: "none" }}
            onClick={() => setPriceDialogVisible(!priceDialogVisible)}
            text
          />
        </div>
        <Button
          className="flex flex-row"
          style={{ gap: "5px", boxShadow: "none" }}
          severity="secondary"
          onClick={() => setNewOrderDialogVisible(true)}
          text
        >
          <i
            className="fas fa-clock"
            style={{ fontSize: "16px", color: "var(--surface-800)" }}
          ></i>
          <span className="text-600 font-medium text-sm">New Order</span>
        </Button>
        <div
          className="border-right-2 border-300"
          style={{ margin: "7px" }}
        ></div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <i
                className="fa-solid fa-plus"
                style={{ fontSize: "16px", color: "var(--surface-800)" }}
              ></i>
            }
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
          />
        </div>
        <div className="flex">
          <Button
            icon={
              <i
                className="fa-solid fa-minus"
                style={{ fontSize: "16px", color: "var(--surface-800)" }}
              ></i>
            }
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
          />
        </div>
        <div
          className="border-right-2 border-300"
          style={{ margin: "7px" }}
        ></div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <i
                className="fa-solid fa-bacon"
                style={{ fontSize: "16px", color: "var(--surface-800)" }}
              ></i>
            }
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
          />
        </div>
        <div
          className="border-right-2 border-300"
          style={{ margin: "7px" }}
        ></div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <i
                className="fa-solid fa-calendar-days"
                style={{ fontSize: "16px", color: "var(--surface-800)" }}
              ></i>
            }
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
          />
        </div>
        <div
          className="border-right-2 border-300"
          style={{ margin: "7px" }}
        ></div>
        <div className="flex align-items-center justify-content-center">
          <Button
            icon={
              <i
                className="fa-solid fa-file-lines"
                style={{ fontSize: "16px", color: "var(--surface-800)" }}
              ></i>
            }
            severity="secondary"
            style={{ boxShadow: "none" }}
            text
          />
        </div>
      </div>

      <div className="flex align-items-center justify-content-center mr-1">
        <Button
          severity="secondary"
          style={{ boxShadow: "none" }}
          text
          className="flex align-items-center text-900 font-medium text-sm"
          onClick={() => navigate("/dashboard/home")}
        >
          <div className="flex flex-row align-items-center justify-content-center">
            <span
              className="text-900 align-items-center justify-content-center"
              style={{ marginRight: "5px" }}
            >
              Dashboard
            </span>
            <i
              className="fa-solid fa-chalkboard-user"
              style={{
                fontSize: "16px",
                color: "var(--surface-800)",
              }}
            ></i>
          </div>
        </Button>

        <div
          className="border-left-2 border-300"
          style={{ margin: "7px", height: "25px" }}
        ></div>
        <Button
          icon="pi pi-arrow-up-right-and-arrow-down-left-from-center"
          severity="secondary"
          style={{
            fontSize: "16px",
            color: "var(--surface-800)",
            boxShadow: "none",
          }}
          text
          onClick={toggleFullScreen}
        />
      </div>
    </div>
  );
}
